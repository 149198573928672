import React from "react";
import { UseGlobalContext } from "../pages/globalContext/GlobalContext";

const SelectCompany = () => {
  const { selectedCompany, setSelectedCompany, companyData } =
    UseGlobalContext();

  return (
    <select
      name="company"
      id="company"
      value={selectedCompany?.id}
      onChange={(e) => {
        setSelectedCompany(companyData?.find((d) => d?.id == e.target.value));
        window.localStorage.setItem(
          "selectedOrg",
          JSON.stringify(companyData?.find((d) => d?.id == e.target.value))
        );
      }}
      className="border-2 bg-[#0e940e] p-2 text-white my-2 text-[14px] rounded-md "
    >
      {companyData?.map((d, i) => (
        <option key={d?.id} value={d?.id}>
          {d?.name}
        </option>
      ))}
    </select>
  );
};

export default SelectCompany;

import axios from "axios";
import { listAll, ref } from "firebase/storage";
import React, { createContext, useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { storage } from "../../FirebaseConfig";
import jwt_decode from "jwt-decode";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
const GlobalContextProvider = createContext();

export const UseGlobalContext = () => {
  return useContext(GlobalContextProvider);
};
const companyData = [
  {
    id: 1,
    name: "Mirats Insights Lucknow",
    value: "purchaseOrder",
    supplier: "miratsinsights",
    remittance: "remittanceAdvice",
  },
  {
    id: 2,
    name: "Mirats United Lucknow",
    value: "purchaseOrdersMiratsQuanto",
    supplier: "mirats",
    remittance: "remittanceAdviceQuanto",
  },
  {
    id: 3,
    name: "Mirats Insights Mumbai",
    value: "purchaseOrdersMumbai",
    supplier: "miratsinsightsmumbai",
    remittance: "remittanceAdviceMumbai",
  },
];
const GlobalContext = ({ children }) => {
  console.log();
  const [bankTransaction, setBankTransaction] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: "",
    msg: "",
  });
  const [selectedCompany, setSelectedCompany] = useState(
    JSON.parse(window?.localStorage?.getItem("selectedOrg")) || companyData[0]
  );
  const [getingImgFromFolder, setGetingImgFromFolder] = useState([]);
  const [exportExcel, setExportExcel] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);

  const getBankTransaction = () => {
    axios
      .get("https://finance.miratsoneservices.com/api/get-bank-transaction")
      .then((data) => {
        setBankTransaction(data?.data);
      });
  };

  useEffect(() => {
    getBankTransaction();
    listAll(ref(storage, ref(`bank_statement`))).then((res) => {
      res.prefixes.forEach((folderRef) => {
        setGetingImgFromFolder((prev) => [...prev, folderRef.name]);
      });
      res.items.forEach((itemref) => {
        console.log(itemref);
      });
    });
  }, []);

  // console.log(bankTransaction);
  let name = "sonumonu";

  // snackbar
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ open: false, severity: "", msg: "" });
  };

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  const tmpToken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbXBsb3llZXNfaWQiOjI3LCJlbWFpbCI6ImNoYXJtaS5wYXRlbEBtaXJhdHNpbnNpZ2h0cy5jb20iLCJuYW1lIjoiQ2hhcm1pIFBhdGVsIiwid29ya1NwYWNlQXBwc0FjY2VzcyI6W3siaWQiOjksImNyZWF0ZWRBdCI6IjIwMjMtMTEtMjhUMTE6NDQ6NTQuMDAwWiIsInVwZGF0ZWRBdCI6IjIwMjMtMTEtMjhUMTE6NDQ6NTQuMDAwWiIsImVtcGxveWVlSWQiOjI3LCJwb3J0YWxJZCI6MX0seyJpZCI6NzksImNyZWF0ZWRBdCI6IjIwMjMtMTEtMjlUMDQ6Mzg6MjYuMDAwWiIsInVwZGF0ZWRBdCI6IjIwMjMtMTEtMjlUMDQ6Mzg6MjYuMDAwWiIsImVtcGxveWVlSWQiOjI3LCJwb3J0YWxJZCI6M31dLCJjcmVhdGVkQXQiOiIyMDIzLTAxLTI0VDAzOjEyOjEzLjAwMFoiLCJpYXQiOjE3MjU0MjMwNTB9.AmwDcNrE-c163zh9r6x1WIFt3Z61emz7XIZy6u_J_cc";

  const [userData, setUserData] = useState({});

  async function getUserData() {
    const token = getCookie("token");
    // const token = tmpToken;
    const decodedToken = jwt_decode(token);
    // console.log("decodedToken", decodedToken);

    axios
      .get(
        `https://peoples.miratsoneservices.com/api/v1/peoples/get_employeeData/${decodedToken?.employees_id}`
      )
      .then((res) => {
        // console.log("token res", res.data);
        setUserData({
          ...res?.data,
          basicInfo: {
            ...res?.data?.basicInfo?.[0],
          },
          workDetails: {
            ...res?.data?.workDetails?.[0],
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    getUserData();
  }, []);

  const value = {
    bankTransaction,
    name,
    getingImgFromFolder,
    setGetingImgFromFolder,
    getBankTransaction,
    setSnackbar,
    snackbar,
    disableBtn,
    setDisableBtn,
    userData,
    setUserData,
    getUserData,
    companyData,
    selectedCompany,
    setSelectedCompany,
  };

  return (
    <GlobalContextProvider.Provider value={value}>
      {children}
      <Snackbar
        open={snackbar?.open}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={snackbar?.severity}
          sx={{ width: "100%" }}
        >
          {snackbar?.msg}
        </Alert>
      </Snackbar>
    </GlobalContextProvider.Provider>
  );
};

export default GlobalContext;

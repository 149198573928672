import React, { useState } from "react";
import styles from "../invoices/invoice.module.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import loader from "../../assets/loader.gif";
import { styled } from "@mui/system";
import TablePagination, {
  tablePaginationClasses as classes,
} from "@mui/base/TablePagination";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Header from "../../components/header/Header";
import { useEffect } from "react";
import { Button } from "@mui/material";
import {
  MdOutlineMarkEmailUnread,
  MdOutlineMarkEmailRead,
} from "react-icons/md";
import { UseGlobalContext } from "../globalContext/GlobalContext";
import { getCountries, getCities, getStates, paymentMethod } from "../../utils";
import * as XLSX from "xlsx";
var CryptoJS = require("crypto-js");

const ITEM_HEIGHT = 48;

const blue = {
  200: "#A5D8FF",
  400: "#3399FF",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
    & .${classes.spacer} {
      display: none;
    }
  
    & .${classes.toolbar}  {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          gap: 12px;
          padding: 0.5em 0em;
  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
  
    & .${classes.selectLabel} {
      margin: 0;
    }
  
    & .${classes.select}{
      padding: 0.1em 1em;
          border-radius: 5px;
          box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
          rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
          border: none;
          font-weight: 700;
          font-size: 14px;
          color: #484848;
          outline: none;
    }
  
      &:hover {
        background-color: ${
          theme.palette.mode === "dark" ? grey[800] : grey[50]
        };
      }
  
      &:focus {
        outline: 1px solid ${
          theme.palette.mode === "dark" ? blue[400] : blue[200]
        };
      }
    }
  
    & .${classes.displayedRows} {
      margin: 0;
  
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
  
    & .${classes.actions} {
      display: flex;
      gap: 0.5rem;
      padding: 0.5em;
      border: none;
    }
  
    & .${classes.actions} > button {
      padding: 0.1em 1em;
      border: 1px solid #828282;
      cursor: pointer;
      background-color: white;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
      border: none;
    }
    `
);

function getMonthName(monthNumber) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (monthNumber < 1 || monthNumber > 12) {
    return "Invalid month number. Please use a number between 1 and 12.";
  }

  return months[monthNumber - 1];
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  py: 2,
  pl: 2,
};

const AuditInvoices = () => {
  const { setSnackbar } = UseGlobalContext();
  const [newTab, setNewTab] = useState(0);
  const [tab, setTab] = useState("all");
  const [modalOpen, setModalOpen] = useState({
    paid: false,
    cancel: false,
    active: false,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [menuOption, setMenuOption] = React.useState(null);
  const [invoiceInputData, setInvoiceInputData] = useState({});
  const navigate = useNavigate();

  const openMenu = Boolean(menuOption);
  const handleMenuClick = async (event, data) => {
    setMenuOption(event.currentTarget);

    setInvoiceInputData({
      _id: data?._id,
      remittanceCreate: true,
      remittanceData: {
        supplier: data?.supplier?._id,
        tableData: [
          {
            invoice: data?._id,
            invoiceNo: data?.invoiceNumber,
            Amt: data?.total,
          },
        ],
        totalAmount: data?.total,
      },
    });
    await updateDataBySupplierDetails(data);
  };

  const handleMenuClose = () => {
    setMenuOption(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleModalOpen = (name) => {
    setModalOpen((prev) => ({ ...prev, [name]: true }));
  };

  const handleModalClose = (name) => {
    setModalOpen((prev) => ({ ...prev, [name]: false }));
  };

  const handelInvoiceInputData = (e) => {
    let { name, value } = e.target;
    console.log(name, value);
    if (name === "date" || name === "paymentMethod") {
      setInvoiceInputData((prev) => ({ ...prev, [name]: value }));
    } else if (name === "currency") {
      setInvoiceInputData((prev) => ({
        ...prev,
        [name]: { label: value, value: value },
      }));
    } else {
      setInvoiceInputData((prev) => ({ ...prev, [name]: Number(value) }));
    }
  };

  const handleUpdatePaidInvoice = async () => {
    handleModalClose("paid");
    try {
      if (!invoiceInputData?._id) {
        return;
      }
      const updateInvoice = await axios.patch(
        `https://api.audit.miratsquanto.com/api/v1/mirats-quanto-audit/finance/update-invoice/${invoiceInputData?._id}`,
        {
          status: "Paid",
        }
      );
      console.log("Updated Successfully!");
      getAllAuditInvoices();
      setInvoiceInputData({});
      setSnackbar({
        open: true,
        severity: "success",
        msg: `Invoice Status updated Successfully! `,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filters, setFilters] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
    payment_status: "",
  });
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const encryptText = (text) => {
    var b64 = CryptoJS.AES.encrypt(text, "MiratsInsights").toString();
    var e64 = CryptoJS.enc.Base64.parse(b64);
    var eHex = e64.toString(CryptoJS.enc.Hex);
    return eHex;
  };

  // GET ALL AUDIT INVOICES:
  const [auditInvoices, setAuditInvoices] = useState([]);
  const getAllAuditInvoices = async () => {
    try {
      const data = await axios.get(
        `https://api.audit.miratsquanto.com/api/v1/mirats-quanto-audit/finance/all-invoices?year=${Number(
          filters?.year
        )}&month=${Number(filters?.month) + 1}&status=${
          filters?.payment_status
        }&page=${page + 1}&pageSize=${rowsPerPage}`
      );
      setAuditInvoices(data?.data);
    } catch (error) {
      console.log("error in getting audit invoices === >>>");
      console.log(error);
    }
  };

  // GET ALL REMITTANCE:
  const [auditRemittance, setAuditRemittance] = useState([]);
  const getAllRemittanceData = async () => {
    try {
      const data = await axios.get(
        `https://api.audit.miratsquanto.com/api/v1/mirats-quanto-audit/finance/all-remittance?year=${Number(
          filters?.year
        )}&month=${Number(filters?.month) + 1}&page=${
          page + 1
        }&pageSize=${rowsPerPage}`
      );
      setAuditRemittance(data?.data);
    } catch (error) {
      console.log("error in getting audit invoices === >>>");
      console.log(error);
    }
  };

  useEffect(() => {
    if (newTab === 0) {
      getAllAuditInvoices();
    }
    if (newTab === 1) {
      getAllRemittanceData();
    }
  }, [
    filters?.year,
    filters?.month,
    filters?.payment_status,
    page,
    rowsPerPage,
    newTab,
  ]);

  const handleUpdateInvRemittance = async (e) => {
    e.preventDefault();
    handleModalClose("paid");
    try {
      console.log(invoiceInputData);
      if (!invoiceInputData?._id) {
        return;
      }
      const updateInvoice = await axios.patch(
        `https://api.audit.miratsquanto.com/api/v1/mirats-quanto-audit/finance/update-invoice-remittance/${invoiceInputData?._id}`,
        {
          invoiceData: {
            _id: invoiceInputData?._id,
            status: "Paid",
          },
          remittanceData: {
            ...invoiceInputData?.remittanceData,
            date: invoiceInputData?.date,
            paymentMethod: invoiceInputData?.paymentMethod,
            currency: invoiceInputData?.currency,
            tds: invoiceInputData?.tds,
          },
        }
      );
      console.log("Updated Successfully!");
      getAllAuditInvoices();
      setInvoiceInputData({});
      setSnackbar({
        open: true,
        severity: "success",
        msg: `New remittance advice added successfully! `,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const findCountry = async (countryCode) => {
    const countriesData = await getCountries();
    const country = countriesData?.find((d) => d?.iso2 === countryCode);
    if (country) {
      return country?.name;
    }
  };

  const updateDataBySupplierDetails = async (data) => {
    if (data?.supplier) {
      let countryData = "";
      let stateData = "";
      let cityData = "";

      if (data?.supplier?.region?.length === 2) {
        countryData = (await findCountry(data?.supplier?.region)) || "";
      }

      if (data?.supplier?.state?.length <= 3) {
        const allStates = await getStates({
          country:
            data?.supplier?.region?.length <= 3 ? data?.supplier?.region : "",
        });
        const state = allStates?.find((d) => d?.iso2 === data?.supplier?.state);
        if (state) {
          stateData = state?.name;
        } else {
          stateData = "";
        }
      }

      if (data?.supplier?.city) {
        const allCities = await getCities({
          country:
            data?.supplier?.region?.length <= 3 ? data?.supplier?.region : "",
          state:
            data?.supplier?.state?.length <= 3 ? data?.supplier?.state : "",
        });
        const city = allCities?.find((d) => d?.name === data?.supplier?.city);
        if (city) {
          cityData = city?.name;
        } else {
          cityData = "";
        }
      }

      setInvoiceInputData((prev) => ({
        ...prev,
        remittanceData: {
          ...prev?.remittanceData,
          officeAddress: `${
            data?.supplier?.streetAddress1
              ? `${data?.supplier?.streetAddress1}, `
              : ""
          }${
            data?.supplier?.streetAddress2
              ? `${data?.supplier?.streetAddress2}, `
              : ""
          }${countryData}, ${stateData}, ${cityData}`,
        },
      }));
    }
  };

  const downloadExcelSheet = async () => {
    try {
      const month = getMonthName(filters?.month);
      console.log(month);
      const workbook = XLSX.utils.book_new();
      let downloadData = [];
      if (newTab === 0) {
        auditInvoices?.data?.forEach((data) => {
          downloadData.push({
            supplierName: `${data?.supplier?.firstName}${
              data?.supplier?.lastName ? ` ${data?.supplier?.lastName}` : ""
            }`,
            companyName: data?.supplier?.companyName,
            email: data?.supplier?.email,
            invoiceNumber: data?.invoiceNumber,
            poNumber: data?.poNumber,
            invoiceDate: new Date(data?.invoiceDate)?.toLocaleDateString(
              "en-ca"
            ),
            paymentTerm: data?.paymentTerm?.label,
            gstNumber: data?.supplierDetails?.billingAddress?.gstNo || "-",
            amount: data?.total,
            status: data?.invoiceStatus,
          });
        });
      } else {
        auditRemittance?.data?.forEach((data) => {
          downloadData.push({
            supplierName: `${data?.supplier?.firstName}${
              data?.supplier?.lastName ? ` ${data?.supplier?.lastName}` : ""
            }`,
            companyName: data?.supplier?.companyName,
            email: data?.supplier?.email,
            date: new Date(data?.date)?.toLocaleDateString("en-ca"),
            tds: data?.tds,
            officeAddress: data?.officeAddress,
            paymentMethod: paymentMethod.find(
              (d) => d?.value === data?.paymentMethod
            )?.label,
            totalInvoices: data?.tableData?.length,
            invoiceNumbers: data?.tableData
              ?.map((item) => item.invoiceNo)
              ?.join("\n"),
            totalAmount: data?.totalAmount,
            emailSent: data?.emailSent === true ? "True" : "False",
          });
        });
      }
      console.log(auditInvoices);
      const worksheet = XLSX.utils.json_to_sheet(downloadData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(
        workbook,
        tab === 0
          ? `Audit-Invoices(${month}-${filters?.year}).xlsx`
          : `Audit-Remittance(${month}-${filters?.year}).xlsx`
      );
    } catch (error) {
      console.log("error in downloading excel sheet data", error);
    }
  };

  console.log(filters);

  return (
    <div className="space-y-6">
      <Header />

      <div className="space-y-2 ">
        <div className="flex gap-6 items-center px-4 text-md font-medium">
          <h2
            className={
              newTab === 0
                ? "px-2 py-1 bg-blue-500 text-white cursor-pointer"
                : "cursor-pointer hover:px-2 hover:py-1 hover:bg-blue-200 transition-all duration-300"
            }
            onClick={() => {
              setNewTab(0);
            }}
          >
            Invoices
          </h2>
          <h2
            className={
              newTab === 1
                ? "px-2 py-1 bg-blue-500 text-white cursor-pointer"
                : "cursor-pointer hover:px-2 hover:py-1 hover:bg-blue-200 transition-all duration-300"
            }
            onClick={() => {
              setNewTab(1);
            }}
          >
            Remittance
          </h2>
        </div>

        {newTab === 0 ? (
          <div className={styles.invoice_table_container}>
            <div className={styles.left_invoice_table}>
              <div className={styles.table_header}>
                <div className={styles.left_table_header}>
                  <input
                    type="search"
                    className={styles.icon}
                    placeholder="Search...."
                    onChange={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        search: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div
                  className={`${styles.right_header} flex gap-3 items-center`}
                >
                  <button
                    className="py-2 px-2 rounded-md bg-green-600 text-sm font-medium shadow-md  text-white"
                    onClick={downloadExcelSheet}
                  >
                    Download Excel sheet{" "}
                  </button>
                  <select
                    name="month"
                    value={filters?.month}
                    onChange={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }));
                    }}
                  >
                    <option value="0">January</option>
                    <option value="1">February</option>
                    <option value="2">March</option>
                    <option value="3">April</option>
                    <option value="4">May</option>
                    <option value="5">June</option>
                    <option value="6">July</option>
                    <option value="7">August</option>
                    <option value="8">September</option>
                    <option value="9">October</option>
                    <option value="10">November</option>
                    <option value="11">December</option>
                  </select>
                  <select
                    name="year"
                    value={filters?.year || ""}
                    onChange={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }));
                    }}
                  >
                    {Array.from({ length: 7 }, (_, index) => {
                      const year = new Date().getFullYear() - index;
                      return (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className={styles.invoice_table}>
                <div className="flex items-center gap-[50px] px-2 ">
                  <h1
                    onClick={(e) => {
                      setTab("all");
                      setFilters((prev) => ({ ...prev, payment_status: "" }));
                    }}
                    className={`pb-1 text-[#666] text-[18px] font-[500] uppercase cursor-pointer ${
                      tab == "all" &&
                      "border-b-[3px] border-[#be7880] font-[600] transition text-black"
                    }`}
                  >
                    All
                  </h1>
                  <h1
                    onClick={(e) => {
                      setTab("unpaid");
                      setFilters((prev) => ({
                        ...prev,
                        payment_status: "Pending",
                      }));
                    }}
                    className={`pb-1 text-[#666] text-[18px] font-[500] uppercase cursor-pointer ${
                      tab == "unpaid" &&
                      "border-b-[3px] border-[#be7880] font-[600] transition text-black"
                    } `}
                  >
                    Unpaid
                  </h1>
                  <h1
                    onClick={(e) => {
                      setTab("paid");
                      setFilters((prev) => ({
                        ...prev,
                        payment_status: "Paid",
                      }));
                    }}
                    className={`  pb-1 text-[#666] text-[18px] font-[500] uppercase cursor-pointer ${
                      tab == "paid" &&
                      "border-b-[3px] border-[#be7880] font-[600] transition text-black"
                    }`}
                  >
                    Paid
                  </h1>
                  <h1
                    onClick={(e) => {
                      setTab("canceled");
                      setFilters((prev) => ({
                        ...prev,
                        payment_status: "Canceled",
                      }));
                    }}
                    className={`  pb-1 text-[#666] text-[18px] font-[500] uppercase cursor-pointer ${
                      tab == "canceled" &&
                      "border-b-[3px] border-[#be7880] font-[600] transition text-black"
                    }`}
                  >
                    Canceled
                  </h1>
                </div>

                <table aria-label="custom pagination table ">
                  <thead>
                    <tr>
                      <th>Customer</th>
                      <th>Invoice</th>
                      <th>Total Amount</th>
                      <th>Payment</th>
                      <th>Created</th>
                      <th>Status</th>
                      <th>Received</th>
                    </tr>
                  </thead>
                  <tbody>
                    {auditInvoices?.data?.length ? (
                      auditInvoices?.data
                        ?.filter((data) => {
                          return filters?.search
                            ? data?.supplierDetails?.billingAddress?.companyName
                                ?.toLowerCase()
                                ?.includes(filters?.search?.toLowerCase()) ||
                                data?.invoiceNumber?.includes(filters?.search)
                            : data;
                        })
                        ?.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className="flex items-center gap-[10px]">
                                  <a className={styles.customerLink}>
                                    {
                                      data?.supplierDetails?.billingAddress
                                        ?.companyName
                                    }
                                  </a>
                                </div>
                                <p>
                                  {
                                    data?.supplierDetails?.supplierInfo
                                      ?.companyEmail
                                  }
                                </p>
                              </td>
                              <td>
                                <Link
                                  to={`${encryptText(
                                    data?.invoiceNumber
                                  )}-${encryptText(
                                    String(data?.invoiceNumber)
                                  )}`}
                                  className={styles.invoiceNumber}
                                >
                                  {data?.invoiceNumber}
                                </Link>
                                <p className={styles.light}>
                                  {new Date(
                                    data?.invoiceDate
                                  )?.toLocaleDateString("en-ca", {
                                    month: "short",
                                    year: "numeric",
                                    day: "2-digit",
                                  })}
                                </p>
                              </td>
                              <td>
                                <p>
                                  <span>{data?.currency?.label} </span>
                                  <span>{Number(data?.total)?.toFixed(2)}</span>
                                </p>
                                <p className={styles.light}>
                                  <Link
                                    className={styles.invoiceLink}
                                    to={`${encryptText(
                                      data?._id
                                    )}-${encryptText(
                                      String(data?.invoiceNumber)
                                    )}`}
                                  >
                                    View Invoice
                                  </Link>
                                </p>
                              </td>
                              <td className={styles.warning}>
                                <Link
                                  to={`${encryptText(data?._id)}-${encryptText(
                                    String(data?.invoiceNumber)
                                  )}`}
                                >
                                  {data?.currency?.label}{" "}
                                  {data?.total?.toFixed(2)}{" "}
                                </Link>
                                {data?.payment_status === "paid" ? (
                                  ""
                                ) : (
                                  <p>
                                    Overdue by{" "}
                                    {Math.round(
                                      Number(
                                        new Date()?.getTime() -
                                          new Date(data?.invoiceDate)?.getTime()
                                      ) /
                                        (1000 * 3600 * 24)
                                    )?.toFixed(2)}{" "}
                                    days
                                  </p>
                                )}
                              </td>
                              <td className={styles.create}>
                                <p>
                                  {new Date(
                                    data?.invoiceDate
                                  )?.toLocaleDateString("en-in")}
                                </p>
                                {/* <p>Tulsi</p> */}
                              </td>
                              <td>
                                <span
                                  className={
                                    data?.invoiceStatus?.toLowerCase() ===
                                    "pending"
                                      ? "px-2 py-1 bg-yellow-400 text-sm font-semibold"
                                      : data?.invoiceStatus?.toLowerCase() ===
                                        "canceled"
                                      ? styles.canceled
                                      : styles.draft
                                  }
                                >
                                  {data?.invoiceStatus}
                                </span>
                              </td>
                              <td className="text-center">
                                <p>
                                  <span>
                                    {data?.currency?.label}{" "}
                                    {data?.receivedAmt ? data?.receivedAmt : 0}
                                  </span>
                                </p>
                              </td>
                              <td>
                                <div>
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={
                                      open ? "long-menu" : undefined
                                    }
                                    aria-expanded={open ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={(e) => handleMenuClick(e, data)}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                  <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                      "aria-labelledby": "long-button",
                                    }}
                                    anchorEl={menuOption}
                                    open={openMenu}
                                    onClose={handleMenuClose}
                                    anchorOrigin={{
                                      vertical: "middle",
                                      horizontal: "bottom",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                    PaperProps={{
                                      style: {
                                        maxHeight: ITEM_HEIGHT * 4.5,
                                        boxShadow: "none",
                                        marginLeft: "10px",
                                        marginTop: "20px",
                                        borderRadius: "10px",
                                        width: "15ch",
                                        border: "2px solid rgb(175 173 173)",
                                      },
                                    }}
                                  >
                                    {/* {testing ? (
                                  <div className="flex items-center flex-col gap-[10px]">
                                    <h1
                                      className="text-[#545353] font-[600] text-[16px] cursor-pointer"
                                      onClick={(e) => {
                                        handleModalOpen("active");
                                        handleMenuClose();
                                      }}
                                    >
                                      Active
                                    </h1>

                                    <h1
                                      className="text-[#545353] font-[600] text-[16px] cursor-pointer"
                                      onClick={(e) => {
                                        handleModalOpen("paid");
                                        handleMenuClose();
                                      }}
                                    >
                                      Paid
                                    </h1>
                                    <h1
                                      className="text-[#545353] font-[600] text-[16px] cursor-pointer"
                                      onClick={(e) => {
                                        handleModalOpen("cancel");
                                        handleMenuClose();
                                      }}
                                    >
                                      Canceled
                                    </h1>
                                  </div>
                                ) : (
                                  <div className="flex items-center flex-col text-red">
                                    <h1
                                      className="text-[#545353] font-[600] text-[16px] cursor-pointer"
                                      onClick={(e) => {
                                        handleModalOpen("paid");
                                        handleMenuClose();
                                        console.log(data?._id);
                                        setInvoiceInputData((prev) => ({
                                          ...prev,
                                          status: "Paid",
                                        }));
                                      }}
                                    >
                                      Paid
                                    </h1>

                                    <h1
                                      className="text-[#545353] font-[600] text-[16px] cursor-pointer"
                                      onClick={(e) => {
                                        handleModalOpen("cancel");
                                        handleMenuClose();
                                        setInvoiceInputData((prev) => ({
                                          ...prev,
                                          status: "Canceled",
                                        }));
                                      }}
                                    >
                                      Canceled
                                    </h1>
                                  </div>
                                )} */}

                                    <div className="flex items-center flex-col text-red">
                                      <h1
                                        className="text-[#545353] font-[600] text-[16px] cursor-pointer"
                                        onClick={(e) => {
                                          handleModalOpen("paid");
                                          handleMenuClose();
                                          console.log(data?._id);
                                          setInvoiceInputData((prev) => ({
                                            ...prev,
                                            status: "Paid",
                                          }));
                                        }}
                                      >
                                        Paid
                                      </h1>
                                      {/* <h1
                                      className="text-[#545353] font-[600] text-[16px] cursor-pointer"
                                      onClick={(e) => {
                                        handleModalOpen("cancel");
                                        handleMenuClose();
                                        setInvoiceInputData((prev) => ({
                                          ...prev,
                                          status: "Pending",
                                        }));
                                      }}
                                    >
                                      Pending
                                    </h1> */}
                                      <h1
                                        className="text-[#545353] font-[600] text-[16px] cursor-pointer"
                                        onClick={(e) => {
                                          handleModalOpen("cancel");
                                          handleMenuClose();
                                          setInvoiceInputData((prev) => ({
                                            ...prev,
                                            status: "Canceled",
                                          }));
                                        }}
                                      >
                                        Canceled
                                      </h1>
                                    </div>
                                  </Menu>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                    ) : (
                      <div className={styles.loaderContainer}>
                        <img src={loader} alt="" />
                      </div>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <CustomTablePagination
                        rowsPerPageOptions={[
                          5,
                          10,
                          25,
                          { label: "All", value: -1 },
                        ]}
                        colSpan={3}
                        count={auditInvoices?.pagination?.totalInvoices}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        componentsProps={{
                          select: {
                            "aria-label": "rows per page",
                          },
                          actions: {
                            showFirstButton: true,
                            showLastButton: true,
                          },
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>

            {/* paid */}
            <Modal
              open={modalOpen?.paid}
              onClose={(e) => handleModalClose("paid")}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div className="flex flex-col gap-[18px] w-full ">
                  <h1 className="text-[25px]">
                    Do you want to create remittance also ?
                  </h1>
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-2 items-center">
                      <input
                        type="radio"
                        id="no"
                        name="remittance"
                        value="no"
                        checked={
                          invoiceInputData?.remittanceCreate === false
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setInvoiceInputData((prev) => ({
                            ...prev,
                            remittanceCreate: false,
                          }));
                        }}
                      />
                      <label for="no">No, only change status as paid</label>
                    </div>
                    <div className="flex gap-2 items-center">
                      <input
                        type="radio"
                        id="yes"
                        name="remittance"
                        value="yes"
                        checked={
                          invoiceInputData?.remittanceCreate === true
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setInvoiceInputData((prev) => ({
                            ...prev,
                            remittanceCreate: true,
                          }));
                        }}
                      />
                      <label for="yes">Yes, create Remittance also</label>
                    </div>
                  </div>

                  {invoiceInputData?.remittanceCreate ? (
                    <form
                      className="flex flex-col gap-[13px] w-full mt-2"
                      onSubmit={handleUpdateInvRemittance}
                    >
                      <section className="">
                        <p>Remittance Date :</p>
                        <input
                          type="date"
                          name="date"
                          onChange={handelInvoiceInputData}
                          required
                          className="border border-black  w-[95%] py-2 px-2 rounded-[6px] mt-2"
                        />
                      </section>
                      <section>
                        <p>Payment Method :</p>
                        <select
                          name="paymentMethod"
                          id=""
                          required
                          className=" border border-black rounded pl-2 w-[95%] py-2"
                          onChange={handelInvoiceInputData}
                        >
                          <option value="">Select Payment Method</option>
                          <option value="credit_card">Credit Card</option>
                          <option value="debit_card">Debit Card</option>
                          <option value="mobile_payment">Mobile Payment</option>
                          <option value="e_wallet">E-wallets</option>
                          <option value="bank_transfer">Bank Transfer</option>
                          <option value="paypal">PayPal</option>
                          <option value="cash">Cash</option>
                        </select>
                      </section>
                      <section>
                        <p>Currency :</p>
                        <select
                          name="currency"
                          id=""
                          required
                          className=" border border-black rounded pl-2 w-[95%] py-2"
                          onChange={handelInvoiceInputData}
                        >
                          <option value="">Select Currency</option>
                          <option value="INR">INR</option>
                          <option value="USD">USD</option>
                          <option value="EUR">EUR</option>
                          <option value="POUND">POUND</option>
                          <option value="CAD">CAD</option>
                        </select>
                      </section>
                      <section className="">
                        <p>TDS :</p>
                        <input
                          type="number"
                          step="any"
                          required
                          onChange={handelInvoiceInputData}
                          name="tds"
                          className="border border-black  w-[95%] py-2 px-2 rounded-[6px] mt-2"
                        />
                      </section>
                      <div className="flex justify-end">
                        <button
                          className="self-center mr-5 mt-5 w-[25%] py-2 bg-[#0E940E] text-white border-none font-[600]"
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  ) : (
                    <div className="flex justify-end">
                      <button
                        className="self-center mr-5 mt-5 w-[25%] py-2 bg-[#0E940E] text-white border-none font-[600]"
                        onClick={() => {
                          handleUpdatePaidInvoice();
                        }}
                      >
                        Change Status
                      </button>
                    </div>
                  )}
                </div>
              </Box>
            </Modal>

            {/* cancel */}
            <Modal
              open={modalOpen?.cancel}
              onClose={(e) => handleModalClose("cancel")}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div className="flex flex-col gap-[10px] w-full ">
                  <h1 className="text-[21px]">
                    Are you sure you want to cancel invoice ?
                  </h1>
                  <h3 className="text-[17px] font-[700]">
                    {/* {invoiceInputData?.data?.invoice_number} */}
                  </h3>
                  <section className="w-full flex justify-end">
                    <button
                      className="self-center mr-5 mt-5 w-[25%] py-2 bg-[#0E940E] text-white border-none font-[600]"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        handleModalClose("cancel");
                        axios
                          .patch(
                            `https://api.audit.miratsquanto.com/api/v1/mirats-quanto-audit/finance/update-invoice/${invoiceInputData?._id}`,
                            {
                              status: "Canceled",
                              // status: "Pending",
                            }
                          )
                          .then((resp) => {
                            console.log("Updated Successfully");
                            getAllAuditInvoices();
                            setInvoiceInputData({});
                          });
                      }}
                    >
                      Yes
                    </button>
                    <button
                      className="self-center mr-5 mt-5 w-[25%] py-2 bg-[#a0a4a0] text-white border-none font-[600]"
                      onClick={(e) => {
                        setModalOpen((prev) => ({ ...prev, cancel: false }));
                        setInvoiceInputData({});
                      }}
                    >
                      No
                    </button>
                  </section>
                </div>
              </Box>
            </Modal>

            {/* active */}
            <Modal
              open={modalOpen?.active}
              onClose={(e) => handleModalClose("active")}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div className="flex flex-col gap-[10px] w-full ">
                  <h1 className="text-[21px]">
                    Are you sure you want to make this invoice Active ?
                  </h1>
                  <h3 className="text-[17px] font-[700]">
                    {invoiceInputData?.data?.invoice_number}
                  </h3>
                  <section className="w-full flex justify-end">
                    <button
                      className="self-center mr-5 mt-5 w-[25%] py-2 bg-[#0E940E] text-white border-none font-[600]"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        handleModalClose("active");
                        axios
                          .put(
                            `https://finance.miratsoneservices.com/api/update-invoice/${invoiceInputData?.iId}`,
                            {
                              status: "active",
                            }
                          )
                          .then((resp) => {
                            console.log("Updated Successfully");
                            // fetchAllCompanyData();
                          });
                      }}
                    >
                      Yes
                    </button>
                    <button
                      className="self-center mr-5 mt-5 w-[25%] py-2 bg-[#a0a4a0] text-white border-none font-[600]"
                      onClick={(e) => {
                        setModalOpen((prev) => ({ ...prev, active: false }));
                        setInvoiceInputData({});
                      }}
                    >
                      No
                    </button>
                  </section>
                </div>
              </Box>
            </Modal>
          </div>
        ) : (
          <div className={styles.invoice_table_container}>
            <div className={styles.left_invoice_table}>
              <div className={styles.table_header}>
                <div className={styles.left_table_header}>
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={() => {
                      navigate("/create-audit-remittance");
                    }}
                  >
                    Create Remittance
                  </Button>
                  <input
                    type="search"
                    className={styles.icon}
                    placeholder="Search...."
                    onChange={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        search: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className={`${styles.right_header} flex gap-2`}>
                  <button
                    className="py-2 px-2 rounded-md bg-green-600 text-sm font-medium shadow-md  text-white"
                    onClick={downloadExcelSheet}
                  >
                    Download Excel sheet{" "}
                  </button>
                  <select
                    name="month"
                    value={filters?.month}
                    onChange={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }));
                    }}
                  >
                    <option value="0">January</option>
                    <option value="1">February</option>
                    <option value="2">March</option>
                    <option value="3">April</option>
                    <option value="4">May</option>
                    <option value="5">June</option>
                    <option value="6">July</option>
                    <option value="7">August</option>
                    <option value="8">September</option>
                    <option value="9">October</option>
                    <option value="10">November</option>
                    <option value="11">December</option>
                  </select>
                  <select
                    name="year"
                    value={filters?.year || ""}
                    onChange={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }));
                    }}
                  >
                    {Array.from({ length: 7 }, (_, index) => {
                      const year = new Date().getFullYear() - index;
                      return (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className={styles.invoice_table}>
                <table aria-label="custom pagination table ">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Date</th>
                      <th>Supplier Name</th>
                      <th>Total Amount</th>
                      <th>TDS</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {auditRemittance?.data?.length ? (
                      auditRemittance?.data
                        ?.filter((data) => {
                          return filters?.search
                            ? data?.supplier?.companyName
                                ?.toLowerCase()
                                ?.includes(filters?.search?.toLowerCase())
                            : data;
                        })
                        ?.map((data, index) => {
                          return (
                            <tr
                              key={index}
                              className="cursor-pointer"
                              onClick={() => {
                                navigate(`/view-audit-remittance/${data?._id}`);
                              }}
                            >
                              <td>{index + 1}.</td>
                              <td>
                                {new Date(data?.createdAt)?.toLocaleDateString(
                                  "en-IN"
                                )}
                              </td>
                              <td>{data?.supplier?.companyName}</td>
                              <td>{data?.totalAmount?.toFixed(4)}</td>
                              <td>{data?.tds}</td>
                              <td>
                                {data?.emailSent ? (
                                  <MdOutlineMarkEmailRead
                                    size={20}
                                    // onClick={(e) => {
                                    //   e.stopPropagation();
                                    //   setSnackbar({
                                    //     open: true,
                                    //     severity: "error",
                                    //     msg: "Email Already Sent !!",
                                    //   });
                                    // }}
                                  />
                                ) : (
                                  <MdOutlineMarkEmailUnread
                                    size={20}
                                    // onClick={(e) => sendEmail(e, rowData)}
                                  />
                                )}
                              </td>
                            </tr>
                          );
                        })
                    ) : (
                      <div className={styles.loaderContainer}>
                        <img src={loader} alt="" />
                      </div>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <CustomTablePagination
                        rowsPerPageOptions={[
                          5,
                          10,
                          25,
                          { label: "All", value: -1 },
                        ]}
                        colSpan={3}
                        count={auditRemittance?.pagination?.totalRemittance}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        componentsProps={{
                          select: {
                            "aria-label": "rows per page",
                          },
                          actions: {
                            showFirstButton: true,
                            showLastButton: true,
                          },
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>

            {/* right */}

            {/* paid */}
            {/* <Modal
              open={modalOpen?.paid}
              onClose={(e) => handleModalClose("paid")}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div className="flex flex-col gap-[10px] w-full ">
                  <h1 className="text-[25px]">
                    Select All The Required Fields
                  </h1>
                  <form
                    className="flex flex-col gap-[13px] w-full mt-2"
                    onSubmit={handleUpdatePaidInvoice}
                  >
                    <section className="">
                      <p>Received Amount :</p>
                      <input
                        type="number"
                        step="any"
                        required
                        onChange={handelInvoiceInputData}
                        name="receivedAmt"
                        className="border border-black  w-[95%] py-2 px-2 rounded-[6px] mt-2"
                      />
                    </section>
                    <section>
                      <p>TDS :</p>
                      <input
                        type="number"
                        step="any"
                        name="tds"
                        onChange={handelInvoiceInputData}
                        required
                        className="border border-black  w-[95%] py-2 px-2 rounded-[6px] mt-2"
                      />
                    </section>
                    <section>
                      <p>Bank Charges :</p>
                      <input
                        type="number"
                        step="any"
                        name="bankCharges"
                        onChange={handelInvoiceInputData}
                        required
                        className="border border-black w-[95%] py-2 px-2 rounded-[6px] mt-2"
                      />
                    </section>
                    <section>
                      <p>Received Date :</p>
                      <input
                        type="date"
                        name="receivedDate"
                        onChange={handelInvoiceInputData}
                        required
                        className="border border-black  w-[95%] py-2 px-2 rounded-[6px] mt-2"
                      />
                    </section>
                    <section className="w-full flex justify-end">
                      <button
                        className="self-center mr-5 mt-5 w-[25%] py-2 bg-[#0E940E] text-white border-none font-[600]"
                        type="submit"
                      >
                        Submit
                      </button>
                      <button
                        className="self-center mr-5 mt-5 w-[25%] py-2 bg-[#a0a4a0] text-white border-none font-[600]"
                        onClick={(e) => {
                          setModalOpen((prev) => ({ ...prev, paid: false }));
                          setInvoiceInputData({});
                        }}
                      >
                        Cancel
                      </button>
                    </section>
                  </form>
                </div>
              </Box>
            </Modal> */}

            {/* cancel */}
            <Modal
              open={modalOpen?.cancel}
              onClose={(e) => handleModalClose("cancel")}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div className="flex flex-col gap-[10px] w-full ">
                  <h1 className="text-[21px]">
                    Are you sure you want to cancel invoice ?
                  </h1>
                  <h3 className="text-[17px] font-[700]">
                    {/* {invoiceInputData?.data?.invoice_number} */}
                  </h3>
                  <section className="w-full flex justify-end">
                    <button
                      className="self-center mr-5 mt-5 w-[25%] py-2 bg-[#0E940E] text-white border-none font-[600]"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        handleModalClose("cancel");
                        axios
                          .patch(
                            `https://api.audit.miratsquanto.com/api/v1/mirats-quanto-audit/finance/update-invoice/${invoiceInputData?._id}`,
                            {
                              status: "Canceled",
                              // status: "Pending",
                            }
                          )
                          .then((resp) => {
                            console.log("Updated Successfully");
                            getAllAuditInvoices();
                            setInvoiceInputData({});
                          });
                      }}
                    >
                      Yes
                    </button>
                    <button
                      className="self-center mr-5 mt-5 w-[25%] py-2 bg-[#a0a4a0] text-white border-none font-[600]"
                      onClick={(e) => {
                        setModalOpen((prev) => ({ ...prev, cancel: false }));
                        setInvoiceInputData({});
                      }}
                    >
                      No
                    </button>
                  </section>
                </div>
              </Box>
            </Modal>

            {/* active */}
            <Modal
              open={modalOpen?.active}
              onClose={(e) => handleModalClose("active")}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div className="flex flex-col gap-[10px] w-full ">
                  <h1 className="text-[21px]">
                    Are you sure you want to make this invoice Active ?
                  </h1>
                  <h3 className="text-[17px] font-[700]">
                    {invoiceInputData?.data?.invoice_number}
                  </h3>
                  <section className="w-full flex justify-end">
                    <button
                      className="self-center mr-5 mt-5 w-[25%] py-2 bg-[#0E940E] text-white border-none font-[600]"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        handleModalClose("active");
                        axios
                          .put(
                            `https://finance.miratsoneservices.com/api/update-invoice/${invoiceInputData?.iId}`,
                            {
                              status: "active",
                            }
                          )
                          .then((resp) => {
                            console.log("Updated Successfully");
                            // fetchAllCompanyData();
                          });
                      }}
                    >
                      Yes
                    </button>
                    <button
                      className="self-center mr-5 mt-5 w-[25%] py-2 bg-[#a0a4a0] text-white border-none font-[600]"
                      onClick={(e) => {
                        setModalOpen((prev) => ({ ...prev, active: false }));
                        setInvoiceInputData({});
                      }}
                    >
                      No
                    </button>
                  </section>
                </div>
              </Box>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};

export default AuditInvoices;

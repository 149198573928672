import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/header/Header";
import { getCountries, stateObj } from "../../utils";
import { UseGlobalContext } from "../globalContext/GlobalContext";

const DetailAuditRemittance = () => {
  const [edit, setEdit] = useState(false);
  const { setSnackbar } = UseGlobalContext();
  const [inputData, setInputData] = useState({});
  const [loading, setLoading] = useState(false);
  let { id } = useParams();

  const [remittanceData, setRemittanceData] = useState({});
  const getSingleRemittance = async (id) => {
    try {
      const data = await axios.get(
        `https://api.audit.miratsquanto.com/api/v1/mirats-quanto-audit/finance/single-remittance/${id}`
      );
      setRemittanceData(data?.data);
    } catch (error) {
      console.log("error in getting remittance data === ", error);
    }
  };

  const [auditSuppliersInvoices, setAuditSuppliersInvoices] = useState([]);
  const getSupplierInvoices = async (id) => {
    try {
      console.log(id);
      const data = await axios.get(
        `https://api.audit.miratsquanto.com/api/v1/mirats-quanto-audit/finance/suppliers-invoices/${id}`
      );
      setAuditSuppliersInvoices(data?.data);
    } catch (error) {
      console.log("error in getting supplier invoices ===", error);
    }
  };

  useEffect(() => {
    if (id) {
      getSingleRemittance(id);
    }
  }, [id]);

  useEffect(() => {
    if (inputData?.supplier?._id) {
      getSupplierInvoices(inputData?.supplier?._id);
    }
  }, [inputData?.supplier?._id]);

  // ALL SUPPLIERS FROM AUDIT:
  const [auditSuppliersList, setAuditSuppliersList] = useState([]);
  const getAllAuditSuppliersList = async () => {
    try {
      const data = await axios.get(
        `https://api.audit.miratsquanto.com/api/v1/mirats-quanto-audit/all-suppliers`
      );
      setAuditSuppliersList(data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (edit) {
      getAllAuditSuppliersList();
    }
  }, [edit]);

  const updateRemittance = async (e) => {
    try {
      const data = await axios.patch(
        `https://api.audit.miratsquanto.com/api/v1/mirats-quanto-audit/finance/update-remittance/${id}`,
        { ...inputData, supplier: inputData?.supplier?._id }
      );
      setSnackbar({
        open: true,
        severity: "success",
        msg: `Updated remittance successfully!`,
      });
      setEdit(false);
      getSingleRemittance(id);
      console.log("Updated remittance successfully!");
    } catch (error) {
      console.log("error in updating remittance === >>>", error);
    }
  };

  const sendRemittanceEmail = async () => {
    try {
      setLoading(true);
      const data = {
        companyName: remittanceData?.supplier?.companyName,
        currency: remittanceData?.currency?.label,
        amount: remittanceData?.totalAmount?.toFixed(4),
        remittanceID: remittanceData?._id,
      };
      const sendEmail = await axios.post(
        `https://api.audit.miratsquanto.com/api/v1/mirats-quanto-audit/finance/send-remittance-email`,
        data
      );
      // const sendEmail = await axios.post(
      //   `http://localhost:1111/api/v1/mirats-quanto-audit/finance/send-remittance-email`,
      //   data
      // );
      setSnackbar({
        open: true,
        severity: "success",
        msg: `Remittance Email Sent successfully! `,
      });
      console.log("Remittance Email Sent successfully!");
      setLoading(false);
    } catch (error) {
      console.log("there is an error in sending remittance email ==", error);
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div className="w-full bg-[#f5f6f6] min-h-screen flex flex-col items-center ">
        <div className="w-[95%] mt-8 ">
          <div className="w-[50%] flex justify-between items-center">
            <h1 className="text-[24px] font-[600]">Remittance Details</h1>
            <div className="flex items-center gap-[10px]">
              {!edit && (
                <button
                  onClick={(e) => {
                    setEdit(true);
                    setInputData(remittanceData);
                  }}
                  className="border px-6 rounded-md bg-[#0E940e] text-white py-2"
                >
                  Edit
                </button>
              )}
              {!edit &&
                (!loading ? (
                  <button
                    onClick={(e) => {
                      sendRemittanceEmail();
                    }}
                    className=" px-4 py-2 rounded-md border border-[#0E940e] text-[#0E940e]"
                  >
                    Send Email
                  </button>
                ) : (
                  <button
                    className="flex items-center gap-1 px-4 py-2 rounded-md border border-gray-500 text-gray-500 cursor-not-allowed"
                    disabled
                  >
                    <div class="flex  items-center justify-center ">
                      <div class="w-4 h-4 border-2 border-gray-300 border-t-blue-500 rounded-full animate-spin"></div>
                    </div>
                    Send Email
                  </button>
                ))}
              {edit && (
                <button
                  onClick={(e) => {
                    setEdit(false);
                  }}
                  className="border  border-[#0E940e] px-6 rounded-md text-[#0E940e] py-2"
                >
                  Cancel
                </button>
              )}
              {edit && (
                <button
                  onClick={(e) => updateRemittance()}
                  className="border px-6 rounded-md border-[#0E940e]  bg-[#0E940e] text-white  py-2"
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="w-[95%] mt-5">
          <div className="w-[50%] flex flex-col gap-[15px] mt-3">
            <section className="w-full flex items-center gap-[20px]">
              <p className="text-[18px]">Supplier Name :</p>
              {edit && (
                <select
                  name="supplier_name"
                  required
                  value={inputData?.supplier?._id}
                  onChange={(e) => {
                    const findSupplier = auditSuppliersList?.data?.find(
                      (data) => data?._id === e.target.value
                    );
                    setInputData((prev) => ({
                      ...prev,
                      supplier: e.target.value,
                      officeAddress: `${
                        findSupplier?.streetAddress1
                          ? `${findSupplier?.streetAddress1}, `
                          : ""
                      }${
                        findSupplier?.streetAddress2
                          ? `${findSupplier?.streetAddress2}, `
                          : ""
                      }${
                        findSupplier?.region
                          ? `${
                              stateObj[findSupplier?.region]
                                ? stateObj[findSupplier?.region]
                                : ""
                            } `
                          : ""
                      }${
                        findSupplier?.state
                          ? `${
                              stateObj[findSupplier?.state]
                                ? stateObj[findSupplier?.state]
                                : ""
                            } `
                          : ""
                      }${
                        findSupplier?.city
                          ? `${
                              stateObj[findSupplier?.city]
                                ? stateObj[findSupplier?.city]
                                : ""
                            } `
                          : ""
                      }`,
                    }));
                  }}
                >
                  <option value="">Select Supplier</option>
                  {auditSuppliersList?.data?.map((data, ind) => (
                    <option value={data?._id} key={ind}>
                      {data.lastName
                        ? `${data.firstName} ${data.lastName}`
                        : data.firstName}
                    </option>
                  ))}
                </select>
              )}
              {!edit && (
                <h1 className="text-[20px] font-[600]">
                  {remittanceData?.supplier?.lastName
                    ? `${remittanceData?.supplier?.firstName} ${remittanceData?.supplier?.lastName}`
                    : remittanceData?.supplier?.firstName}
                </h1>
              )}
            </section>
            <section className="w-full flex items-center gap-[20px]">
              <p className="text-[18px]">Remittance Date :</p>
              {edit && (
                <input
                  type="date"
                  name="date"
                  required
                  className=" min-w-[30%] border border-black rounded pl-2"
                  value={new Date(inputData?.date)?.toLocaleDateString("en-ca")}
                  onChange={(e) =>
                    setInputData((prev) => ({
                      ...prev,
                      date: e.target.value,
                    }))
                  }
                />
              )}
              {!edit && (
                <h1 className="text-[20px] font-[600]">
                  {new Date(remittanceData?.date)?.toLocaleDateString("en-ca", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  })}
                </h1>
              )}
            </section>
            <section className="w-full flex items-center gap-[20px]">
              <p className="text-[18px]">Payment Method :</p>
              {edit && (
                <select
                  name="payment_method"
                  id=""
                  required
                  className=" min-w-[30%] border border-black rounded pl-2"
                  value={inputData?.paymentMethod}
                  onChange={(e) =>
                    setInputData((prev) => ({
                      ...prev,
                      paymentMethod: e.target.value,
                    }))
                  }
                >
                  <option value="">Select Payment Method</option>
                  <option value="credit_card">Credit Card</option>
                  <option value="debit_card">Debit Card</option>
                  <option value="mobile_payment">Mobile Payment</option>
                  <option value="e_wallet">E-wallets</option>
                  <option value="bank_transfer">Bank Transfer</option>
                  <option value="paypal">PayPal</option>
                  <option value="cash">Cash</option>
                </select>
              )}
              {!edit && (
                <h1 className="text-[20px] font-[600] capitalize">
                  {remittanceData?.paymentMethod &&
                  remittanceData?.paymentMethod?.includes("_")
                    ? `${remittanceData?.paymentMethod?.split("_")[0]} ${
                        remittanceData?.paymentMethod?.split("_")[1]
                      }`
                    : remittanceData?.paymentMethod}
                </h1>
              )}
            </section>
            <section className="w-full flex items-center gap-[20px]">
              <p className="text-[18px]">Currency :</p>
              {edit && (
                <select
                  name="currency"
                  required
                  id=""
                  className=" min-w-[30%] border border-black rounded pl-2"
                  value={inputData?.currency.label}
                  onChange={(e) =>
                    setInputData((prev) => ({
                      ...prev,
                      currency: {
                        label: e.target.options[e.target.selectedIndex]?.text,
                        value: e.target.value,
                      },
                    }))
                  }
                >
                  <option value="">Select Currency</option>
                  <option value="INR">INR</option>
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                  <option value="POUND">POUND</option>
                  <option value="CAD">CAD</option>
                </select>
              )}
              {!edit && (
                <h1 className="text-[20px] font-[600]">
                  {remittanceData?.currency?.value}
                </h1>
              )}
            </section>
            <section className="w-full flex items-center gap-[20px]">
              <p className="text-[18px]">Total Amount :</p>
              {edit && (
                <input
                  type="number"
                  required
                  name="total_amount"
                  value={inputData?.totalAmount}
                  className=" min-w-[30%] border border-black rounded pl-2"
                  onChange={(e) =>
                    setInputData((prev) => ({
                      ...prev,
                      totalAmount: Number(e.target.value),
                    }))
                  }
                />
              )}
              {!edit && (
                <h1 className="text-[20px] font-[600]">
                  {remittanceData?.totalAmount?.toFixed(4)}{" "}
                  {remittanceData?.currency?.value}
                </h1>
              )}
            </section>
            <section className="w-full flex items-center gap-[20px]">
              <p className="text-[18px]">TDS Amount :</p>
              {edit && (
                <input
                  type="number"
                  required
                  name="tds_amount"
                  className=" min-w-[30%] border border-black rounded pl-2"
                  value={inputData?.tds}
                  onChange={(e) =>
                    setInputData((prev) => ({
                      ...prev,
                      tds: Number(e.target.value),
                    }))
                  }
                />
              )}
              {!edit && (
                <h1 className="text-[20px] font-[600]">
                  {remittanceData?.tds} {remittanceData?.currency?.value}
                </h1>
              )}
            </section>
          </div>
          <div className="w-[50%]  flex flex-col gap-[15px] mt-8">
            <table className="text-left border-2 border-[#c2c0c0]">
              <thead>
                <tr>
                  <th className="border-2 border-[#c2c0c0] w-[50%] px-2">
                    Invoice Number
                  </th>
                  <th className="border-2 border-[#c2c0c0] w-[50%] px-2">
                    Amount
                  </th>
                </tr>
              </thead>
              {!edit ? (
                <tbody>
                  {remittanceData?.tableData?.map((data, ind) => (
                    <tr key={ind}>
                      <td className="border-2 border-[#c2c0c0] px-2">
                        {data?.invoiceNo}
                      </td>
                      <td className="border-2 border-[#c2c0c0] px-2">
                        {data?.Amt?.toFixed(4)} {inputData?.currency?.value}
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  {inputData?.tableData?.map((data, ind) => (
                    <tr key={ind}>
                      <td className="border-2 border-[#c2c0c0] px-2 py-1">
                        <select
                          name="supplier_name"
                          required
                          value={data?.invoice}
                          onChange={(e) => {
                            let findInv = auditSuppliersInvoices?.find(
                              (data) => {
                                return data?._id === e.target.value;
                              }
                            );
                            let tempData = inputData?.tableData;
                            tempData[ind] = {
                              invoice: e.target.value,
                              invoiceNo:
                                e.target.options[e.target.selectedIndex]?.text,
                              Amt: findInv?.total,
                            };
                            setInputData((prev) => ({
                              ...prev,
                              tableData: tempData,
                              totalAmount: tempData?.reduce(
                                (acc, curr) => (acc += curr?.Amt),
                                0
                              ),
                            }));
                          }}
                        >
                          <option value="">Select Invoice</option>
                          {auditSuppliersInvoices
                            ?.filter(
                              (item) =>
                                !inputData?.tableData?.some(
                                  (row, rowIndex) =>
                                    row?.invoice === item?._id &&
                                    rowIndex !== ind
                                )
                            )
                            ?.map((data, ind) => (
                              <option value={data?._id} key={ind}>
                                {data.invoiceNumber}
                              </option>
                            ))}
                        </select>
                      </td>
                      <td className="border-2 border-[#c2c0c0] px-2">
                        <input
                          type="number"
                          name="amount"
                          step="any"
                          required
                          value={data?.Amt}
                          disabled
                        />
                      </td>
                      <span
                        style={{
                          display: inputData?.tableData?.length == 1 && "none",
                          fontSize: "24px",
                          cursor: "pointer",
                          marginRight: "-25px",
                          marginLeft: "10px",
                        }}
                        onClick={(e) => {
                          const tableData = inputData?.tableData?.filter(
                            (f, i) => i !== ind
                          );
                          setInputData((prev) => ({
                            ...prev,
                            tableData: tableData,
                            totalAmount: tableData?.reduce(
                              (acc, curr) => (acc += curr?.Amt),
                              0
                            ),
                          }));
                        }}
                      >
                        -
                      </span>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
            {edit && (
              <div
                style={{
                  textAlign: "right",
                  width: "100%",
                  fontSize: "28px",
                  cursor: "pointer",
                  marginTop: "-10px",
                }}
                onClick={(e) =>
                  setInputData((prev) => ({
                    ...prev,
                    tableData: [...prev?.tableData, { amount: 0, billno: "" }],
                  }))
                }
              >
                +
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailAuditRemittance;

import styles from "./Header.module.css";
import logo from "../../assets/miratsLogo.png";
import { Link, Navigate, NavLink, useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { Avatar } from "@nextui-org/react";
import { memo, useState } from "react";
import { Logout } from "@mui/icons-material";
import { signOut } from "firebase/auth";
import { auth } from "../../FirebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { UseGlobalContext } from "../../pages/globalContext/GlobalContext";
import SelectCompany from "../SelectCompany";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { userData, setSnackbar } = UseGlobalContext();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const navigate = useNavigate();
  // console.log(user);
  const handleClose = () => {
    setAnchorEl(null);
  };

  // console.log(userData);

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  const logOut = () => {
    document.cookie =
      "token" +
      "=" +
      (getCookie("token") || "") +
      "; expires=" +
      "Thu, 01 Jan 1970 00:00:01 GMT" +
      ";domain=.miratsoneservices.com;path=/";
    navigate("/");
    setSnackbar({
      open: true,
      severity: "success",
      msg: "Logout Successfully!",
    });
  };
  // console.log(userData);
  return (
    <>
      <div className={styles.header}>
        <header>
          <div className={styles.header_left}>
            <figure>
              <img src={logo} alt="" />
            </figure>
            <div className={styles.navCenterBody}>
              <NavLink
                to="/"
                className={({ isActive }) => (isActive ? styles.active : "")}
              >
                <span
                  className={({ isActive }) =>
                    isActive ? styles.nav_name : ""
                  }
                >
                  Dashboard
                </span>
              </NavLink>

              <NavLink
                to="/invoices"
                className={({ isActive }) => (isActive ? styles.active : "")}
              >
                <span
                  className={({ isActive }) =>
                    isActive ? styles.nav_name : ""
                  }
                >
                  Invoices
                </span>
              </NavLink>
              <NavLink
                to="/suppliers"
                className={({ isActive }) => (isActive ? styles.active : "")}
              >
                <span
                  className={({ isActive }) =>
                    isActive ? styles.nav_name : ""
                  }
                >
                  Suppliers
                </span>
              </NavLink>
              <NavLink
                to="/vendor-invoice"
                className={({ isActive }) => (isActive ? styles.active : "")}
              >
                <span
                  className={({ isActive }) =>
                    isActive ? styles.nav_name : ""
                  }
                >
                  Vendor Invoices
                </span>
              </NavLink>
              <NavLink
                to="/client-report"
                className={({ isActive }) => (isActive ? styles.active : "")}
              >
                <span
                  className={({ isActive }) =>
                    isActive ? styles.nav_name : ""
                  }
                >
                  Client Report
                </span>
              </NavLink>
              <NavLink
                to="/clients"
                className={({ isActive }) => (isActive ? styles.active : "")}
              >
                <span
                  className={({ isActive }) =>
                    isActive ? styles.nav_name : ""
                  }
                >
                  Clients
                </span>
              </NavLink>
              <NavLink
                to="/project-orders"
                className={({ isActive }) => (isActive ? styles.active : "")}
              >
                <span
                  className={({ isActive }) =>
                    isActive ? styles.nav_name : ""
                  }
                >
                  Project Orders
                </span>
              </NavLink>
              <NavLink
                to="/by-vendors"
                className={({ isActive }) => (isActive ? styles.active : "")}
              >
                <span
                  className={({ isActive }) =>
                    isActive ? styles.nav_name : ""
                  }
                >
                  PO by Vendors
                </span>
              </NavLink>
              {/* <span className={styles.dropdownContainer}>
                <NavLink
                  to="/project-orders"
                  className={({ isActive }) => (isActive ? styles.active : "")}
                >
                  <span
                    
                    className={styles.myNav}
                  >
                    Project Orders
                  </span>
                </NavLink>

                <ul className={styles.dropdownLists}>
                  <li>
                    <NavLink
                      to="/by-vendors"
                      className={({ isActive }) =>
                        isActive ? styles.active : ""
                      }
                    >
                      <span className={styles.nav_name}>By Vendors</span>
                    </NavLink>
                  </li>
                </ul>
              </span> */}
              <NavLink
                to="/companies"
                className={({ isActive }) => (isActive ? styles.active : "")}
              >
                <span
                  className={({ isActive }) =>
                    isActive ? styles.nav_name : ""
                  }
                >
                  Companies
                </span>
              </NavLink>
              <NavLink
                to="/remittance-advice"
                className={({ isActive }) => (isActive ? styles.active : "")}
              >
                <span
                  className={({ isActive }) =>
                    isActive ? styles.nav_name : ""
                  }
                >
                  Remittance Advise
                </span>
              </NavLink>
              <NavLink
                to="/payment"
                className={({ isActive }) => (isActive ? styles.active : "")}
              >
                <span
                  className={({ isActive }) =>
                    isActive ? styles.nav_name : ""
                  }
                >
                  Payments
                </span>
              </NavLink>
              <NavLink
                to="/audit"
                className={({ isActive }) => (isActive ? styles.active : "")}
              >
                <span
                  className={({ isActive }) =>
                    isActive ? styles.nav_name : ""
                  }
                >
                  Audit
                </span>
              </NavLink>
              {/* <NavLink
                to="/bank"
                className={({ isActive }) => (isActive ? styles.active : "")}
              >
                <span className={styles.nav_name}>Bank</span>
              </NavLink>
              <NavLink
                to="/companies"
                className={({ isActive }) => (isActive ? styles.active : "")}
              >
                <span className={styles.nav_name}>Companies</span>
              </NavLink>
              <NavLink
                to="/expense"
                className={({ isActive }) => (isActive ? styles.active : "")}
              >
                <span className={styles.nav_name}>Expense</span>
              </NavLink> */}
            </div>
          </div>
          <div className={styles.right}>
            <section>
              {/* <Link className={styles.create_company} to="/create-company">
                Create Company
              </Link> */}
            </section>
            <section className={styles.sign_in}>
              {/* <section className={styles.sign_in_details}>
                <p>Rohan Gupta</p>
                <p>Recruitment Coordinator</p>
              </section> */}

              <Box>
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Avatar
                      src={userData?.basicInfo?.profile_picture}
                      size="lg"
                    />
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem>
                  <Avatar
                    size="sm"
                    src={userData?.basicInfo?.profile_picture}
                    color="primary"
                    bordered
                    className={styles.avatar_img}
                  />{" "}
                  <span className={styles.avatar_profile}>
                    {userData?.basicInfo?.email}
                  </span>
                </MenuItem>

                <MenuItem onClick={logOut}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </section>
          </div>
        </header>
      </div>
    </>
  );
};

export default memo(Header);

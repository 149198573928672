import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header/Header";
import { getCountries, stateObj, getCities, getStates } from "../../utils";
import { UseGlobalContext } from "../globalContext/GlobalContext";
import styles from "../vendors/vendorInvoice/addVenodorInvoice/AddVendorInvoice.module.css";

const CreateAuditRemittanceAdvice = () => {
  const navigate = useNavigate();
  const { setSnackbar } = UseGlobalContext();
  const [inputData, setInputData] = useState({
    date: new Date(),
    total_amount: 0,
    tds_amount: 0,
    tableData: [
      {
        billno: "",
        amount: 0,
      },
    ],
  });

  // ALL SUPPLIERS FROM AUDIT:
  const [auditSuppliersList, setAuditSuppliersList] = useState([]);
  const getAllAuditSuppliersList = async () => {
    try {
      const data = await axios.get(
        `https://api.audit.miratsquanto.com/api/v1/mirats-quanto-audit/all-suppliers`
      );
      setAuditSuppliersList(data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [auditSuppliersInvoices, setAuditSuppliersInvoices] = useState([]);
  const getSupplierInvoices = async (id) => {
    try {
      const data = await axios.get(
        `https://api.audit.miratsquanto.com/api/v1/mirats-quanto-audit/finance/suppliers-invoices/${id}`
      );
      setAuditSuppliersInvoices(data?.data);
    } catch (error) {
      console.log("error in getting supplier invoices ===", error);
    }
  };

  useEffect(() => {
    getAllAuditSuppliersList();
  }, []);

  const createRemittance = async (e) => {
    e.preventDefault();
    try {
      const saveRemittance = await axios.post(
        `https://api.audit.miratsquanto.com/api/v1/mirats-quanto-audit/finance/add-remittance`,
        inputData
      );
      setSnackbar({
        open: true,
        severity: "success",
        msg: `New remittance advice added successfully! `,
      });
      navigate("/audit");
      console.log("New remittance advice added successfully!");
    } catch (error) {
      console.log("error in creating remittance === >>", error);
    }
  };
  console.log(inputData);

  const findCountry = async (countryCode) => {
    const countriesData = await getCountries();
    const country = countriesData?.find((d) => d?.iso2 === countryCode);
    if (country) {
      return country?.name;
    }
  };

  const updateDataBySupplierDetails = async () => {
    if (inputData?.supplier) {
      let countryData = "";
      let stateData = "";
      let cityData = "";

      const findSupplier = auditSuppliersList?.data?.find(
        (data) => data?._id === inputData?.supplier
      );

      if (findSupplier?.region?.length === 2) {
        countryData = (await findCountry(findSupplier?.region)) || "";
      }

      if (findSupplier?.state?.length <= 3) {
        const allStates = await getStates({
          country:
            findSupplier?.region?.length <= 3 ? findSupplier?.region : "",
        });
        const state = allStates?.find((d) => d?.iso2 === findSupplier?.state);
        if (state) {
          stateData = state?.name;
        } else {
          stateData = "";
        }
      }

      if (findSupplier?.city) {
        const allCities = await getCities({
          country:
            findSupplier?.region?.length <= 3 ? findSupplier?.region : "",
          state: findSupplier?.state?.length <= 3 ? findSupplier?.state : "",
        });
        const city = allCities?.find((d) => d?.name === findSupplier?.city);
        if (city) {
          cityData = city?.name;
        } else {
          cityData = "";
        }
      }

      setInputData((prev) => ({
        ...prev,
        officeAddress: `${
          findSupplier?.streetAddress1
            ? `${findSupplier?.streetAddress1}, `
            : ""
        }${
          findSupplier?.streetAddress2
            ? `${findSupplier?.streetAddress2}, `
            : ""
        }${countryData}, ${stateData}, ${cityData}`,
      }));
    }
  };

  useEffect(() => {
    if (inputData?.supplier) {
      getSupplierInvoices(inputData?.supplier);
      updateDataBySupplierDetails();
    }
  }, [inputData?.supplier]);

  return (
    <>
      <Header />

      <div className={styles.invoice_container}>
        <div className="flex items-center justify-between">
          <h1 className={styles.intro_text}>Add Remittance Advice</h1>
        </div>
        <form className={styles.inVoiceForm} onSubmit={createRemittance}>
          <div className={styles.invoice_details}>
            <section className={styles.invoice_details_left}>
              <div className={styles.input_group}>
                <label htmlFor="supplier_name">Supplier</label>
                <select
                  name="supplier_name"
                  required
                  onChange={(e) => {
                    const findSupplier = auditSuppliersList?.data?.find(
                      (data) => data?._id === e.target.value
                    );
                    const bankdetails =
                      findSupplier?.profiling?.["bank-details"];
                    setInputData((prev) => ({
                      ...prev,
                      supplier: e.target.value,
                      bankDetails:
                        inputData?.paymentMethod === "bank_transfer"
                          ? `${
                              bankdetails?.[0]?.response
                                ? `Bank Name - ${bankdetails?.[0]?.response}, `
                                : ""
                            }\n${
                              bankdetails?.[1]?.response
                                ? `Account Holders Name - ${bankdetails?.[1]?.response}, `
                                : ""
                            }\n${
                              bankdetails?.[2]?.response
                                ? `Bank Account Number - ${bankdetails?.[2]?.response}, `
                                : ""
                            }\n${
                              bankdetails?.[3]?.response
                                ? `IFSC Code - ${bankdetails?.[3]?.response}, `
                                : ""
                            }\n${
                              bankdetails?.[4]?.response
                                ? `GST No. - ${bankdetails?.[4]?.response}, `
                                : ""
                            }\n${
                              bankdetails?.[5]?.response
                                ? `PAN Card - ${bankdetails?.[5]?.response}`
                                : ""
                            }`
                          : "",
                    }));
                  }}
                >
                  <option value="">Select Supplier</option>
                  {auditSuppliersList?.data?.map((data, ind) => (
                    <option value={data?._id} key={ind}>
                      {data.lastName
                        ? `${data.firstName} ${data.lastName}`
                        : data.firstName}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.input_group}>
                <label htmlFor="date">Date</label>
                <input
                  type="date"
                  name="date"
                  required
                  value={inputData?.date?.toLocaleDateString("en-ca")}
                  onChange={(e) =>
                    setInputData((prev) => ({
                      ...prev,
                      date: new Date(e.target.value),
                    }))
                  }
                />
              </div>
              <div className={styles.input_group}>
                <label htmlFor="currency">Currency</label>
                <select
                  name="currency"
                  required
                  onChange={(e) =>
                    setInputData((prev) => ({
                      ...prev,
                      currency: {
                        label: e.target.options[e.target.selectedIndex]?.text,
                        value: e.target.value,
                      },
                    }))
                  }
                >
                  <option value="">Select Currency</option>
                  <option value="INR">INR</option>
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                  <option value="POUND">POUND</option>
                  <option value="CAD">CAD</option>
                </select>
              </div>
              <div className={styles.input_group}>
                <label htmlFor="total_amount">Total Amount</label>
                <input
                  type="number"
                  required
                  step="any"
                  name="total_amount"
                  value={inputData?.totalAmount}
                  onChange={(e) =>
                    setInputData((prev) => ({
                      ...prev,
                      totalAmount: Number(e.target.value),
                    }))
                  }
                />
              </div>
              <div className={styles.input_group}>
                <label htmlFor="total_amount">TDS</label>
                <input
                  type="number"
                  required
                  step="any"
                  name="tds_amount"
                  value={inputData?.tds}
                  onChange={(e) =>
                    setInputData((prev) => ({
                      ...prev,
                      tds: Number(e.target.value),
                    }))
                  }
                />
              </div>
            </section>
            <section className={styles.invoice_details_right}>
              <div className={styles.input_group}>
                <label htmlFor="office_address">Office Address</label>
                <textarea
                  name="office_address"
                  id="office_address"
                  cols="10"
                  rows="2"
                  value={inputData?.officeAddress}
                ></textarea>
              </div>
              <div className={styles.input_group}>
                <label htmlFor="payment_method">Payment Method</label>
                <select
                  name="payment_method"
                  id=""
                  required
                  onChange={(e) => {
                    const findSupplier = auditSuppliersList?.data?.find(
                      (data) => data?._id === inputData?.supplier
                    );
                    const bankdetails =
                      findSupplier?.profiling?.["bank-details"];
                    setInputData((prev) => ({
                      ...prev,
                      paymentMethod: e.target.value,
                      bankDetails:
                        e.target.value === "bank_transfer"
                          ? `${
                              bankdetails?.[0]?.response
                                ? `Bank Name - ${bankdetails?.[0]?.response} `
                                : ""
                            }\n${
                              bankdetails?.[1]?.response
                                ? `Account Holders Name - ${bankdetails?.[1]?.response} `
                                : ""
                            }\n${
                              bankdetails?.[2]?.response
                                ? `Bank Account Number - ${bankdetails?.[2]?.response} `
                                : ""
                            }\n${
                              bankdetails?.[3]?.response
                                ? `IFSC Code - ${bankdetails?.[3]?.response} `
                                : ""
                            }\n${
                              bankdetails?.[4]?.response
                                ? `GST No. - ${bankdetails?.[4]?.response} `
                                : ""
                            }\n${
                              bankdetails?.[5]?.response
                                ? `PAN Card - ${bankdetails?.[5]?.response}`
                                : ""
                            }`
                          : "",
                    }));
                  }}
                >
                  <option value="">Select Payment Method</option>
                  <option value="credit_card">Credit Card</option>
                  <option value="debit_card">Debit Card</option>
                  <option value="mobile_payment">Mobile Payment</option>
                  <option value="e_wallet">E-wallets</option>
                  <option value="bank_transfer">Bank Transfer</option>
                  <option value="paypal">PayPal</option>
                  <option value="cash">Cash</option>
                </select>
              </div>

              {inputData?.paymentMethod === "bank_transfer" ? (
                <div className={styles.input_group}>
                  <label htmlFor="office_address">Bank Details</label>
                  <textarea
                    name="office_address"
                    id="office_address"
                    cols="10"
                    rows="4"
                    value={inputData?.bankDetails}
                  ></textarea>
                </div>
              ) : (
                <></>
              )}

              <div className={styles.input_group}>
                <table>
                  <thead>
                    <tr>
                      <th>Invoice Number</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {inputData?.tableData?.map((data, ind) => (
                      <tr>
                        <td>
                          <select
                            name="supplier_name"
                            required
                            value={data?.invoice}
                            onChange={(e) => {
                              let findInv = auditSuppliersInvoices?.find(
                                (data) => {
                                  return data?._id === e.target.value;
                                }
                              );
                              let tempData = inputData?.tableData;
                              tempData[ind] = {
                                invoice: e.target.value,
                                invoiceNo:
                                  e.target.options[e.target.selectedIndex]
                                    ?.text,
                                Amt: findInv?.total,
                              };
                              setInputData((prev) => ({
                                ...prev,
                                tableData: tempData,
                                totalAmount: tempData?.reduce(
                                  (acc, curr) => (acc += curr?.Amt),
                                  0
                                ),
                              }));
                            }}
                          >
                            <option value="">Select Invoice</option>
                            {auditSuppliersInvoices
                              ?.filter(
                                (item) =>
                                  !inputData?.tableData?.some(
                                    (row, rowIndex) =>
                                      row?.invoice === item?._id &&
                                      rowIndex !== ind
                                  )
                              )
                              ?.map((data, ind) => (
                                <option value={data?._id} key={ind}>
                                  {data.invoiceNumber}
                                </option>
                              ))}
                          </select>
                        </td>
                        <td>
                          <input
                            type="number"
                            name="amount"
                            step="any"
                            required
                            value={data?.Amt}
                            disabled
                          />
                        </td>
                        <span
                          style={{
                            fontSize: "28px",
                            cursor: "pointer",
                            display:
                              inputData?.tableData?.length == 1 && "none",
                            marginRight: "-25px",
                            marginLeft: "10px",
                          }}
                          onClick={(e) => {
                            const tableData = inputData?.tableData?.filter(
                              (f, i) => i !== ind
                            );
                            setInputData((prev) => ({
                              ...prev,
                              tableData: tableData,
                              totalAmount: tableData?.reduce(
                                (acc, curr) => (acc += curr?.Amt),
                                0
                              ),
                            }));
                          }}
                        >
                          -
                        </span>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className=" text-right flex justify-end ">
                  <h1
                    className="px-2 bg-blue-500 text-white shadow-md"
                    style={{
                      fontSize: "17px",
                      cursor: "pointer",
                    }}
                    onClick={(e) =>
                      setInputData((prev) => ({
                        ...prev,
                        tableData: [
                          ...prev?.tableData,
                          { amount: 0, billno: "" },
                        ],
                      }))
                    }
                  >
                    +
                  </h1>
                </div>
              </div>
            </section>
          </div>

          <button type="submit">Submit</button>
        </form>
      </div>
    </>
  );
};

export default CreateAuditRemittanceAdvice;

import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { stateObj } from "../../utils";
import Header from "../../components/header/Header";
import ReactToPrint from "react-to-print";
import { HiDownload } from "react-icons/hi";

const chunkArray = (array, start, batchSize) => {
  const chunks = [];
  let i = start;
  while (i < array.length) {
    chunks.push(array.slice(i, i + batchSize));
    i += batchSize;
  }
  return chunks;
};

const DetailInvoice = () => {
  let { invoiceNumber } = useParams();
  const componentRef = useRef();

  const decryptText = (encryptText) => {
    var reb64 = CryptoJS.enc.Hex.parse(encryptText);
    var bytes = reb64.toString(CryptoJS.enc.Base64);
    var decrypt = CryptoJS.AES.decrypt(bytes, "MiratsInsights");
    var plain = decrypt.toString(CryptoJS.enc.Utf8);
    return plain;
  };

  const [invoiceData, setInvoiceData] = useState({});
  const getSingleInvoice = async (id) => {
    try {
      const data = await axios.get(
        `https://api.audit.miratsquanto.com/api/v1/mirats-quanto-audit/finance/single-invoice/${id}`
      );
      setInvoiceData(data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleInvoice(decryptText(invoiceNumber?.split("-")[0]));
  }, [invoiceNumber]);

  console.log(invoiceData);

  let isIndianBilling =
    invoiceData?.supplierDetails?.billingAddress?.country?.value === "IN"
      ? true
      : false;

  let hasGSTNo = invoiceData?.supplierDetails?.billingAddress?.gstNo
    ? true
    : false;

  const remainingBatches = chunkArray(invoiceData?.projectList || [], 6, 10);

  return (
    <div className="space-y-6 ">
      <Header />
      <div className="shadow-md border w-[70%] rounded-md px-6 py-2 flex justify-between items-center">
        <div className="flex items-center gap-2">
          <h2 className="text-lg font-semibold">Invoice</h2>-{" "}
          <p className="tracking-wide font-medium">
            {invoiceData?.supplierDetails?.billingAddress?.companyName}
          </p>
        </div>
        <ReactToPrint
          trigger={() => <HiDownload size={25} cursor="pointer" />}
          content={() => componentRef.current}
          documentTitle={`Invoice-${invoiceData?.supplierDetails?.billingAddress?.companyName}(${invoiceData?.invoiceNumber})`}
        />
      </div>

      <div className="w-[70%]">
        <div ref={componentRef} className="space-y-2  ">
          <div className="space-y-3 ">
            <div
              className={`rounded-[8px] bg-[#f5faff] px-[18px] py-6 space-y-[18px] pdf-page min-h-[99vh]`}
            >
              <div className="flex justify-between items-center px-[18px]">
                <div className="flex items-start gap-5">
                  <div className="space-y-[2px]">
                    <h2 className="text-gray-900 text-lg font-semibold">
                      {
                        invoiceData?.supplierDetails?.billingAddress
                          ?.companyName
                      }
                    </h2>
                    <p className="text-gray-600 font-[400] text-[10px]">
                      {/* {singleSupplierDetails?.website} */}
                    </p>
                    <p className="text-gray-600 font-[400] text-[10px]">
                      {invoiceData?.supplierDetails?.supplierInfo?.companyEmail}
                    </p>
                    <p className="text-gray-600 font-[400] text-[10px]">
                      {invoiceData?.supplierDetails?.supplierInfo?.phoneNo}
                    </p>
                    {invoiceData?.supplierDetails?.bankingInfo?.panCard ? (
                      <p className="text-gray-600 font-[400] text-[10px]">
                        PAN No. -{" "}
                        {invoiceData?.supplierDetails?.bankingInfo?.panCard}
                      </p>
                    ) : (
                      <></>
                    )}
                    {invoiceData?.supplierDetails?.billingAddress?.gstNo ? (
                      <p className="text-gray-600 font-[400] text-[10px]">
                        GST No. -{" "}
                        {invoiceData?.supplierDetails?.billingAddress?.gstNo}
                      </p>
                    ) : (
                      <></>
                    )}

                    {invoiceData?.supplierDetails?.supplierInfo?.cin ? (
                      <p className="text-gray-600 font-[400] text-[10px]">
                        CIN - {invoiceData?.supplierDetails?.supplierInfo?.cin}
                      </p>
                    ) : (
                      <></>
                    )}
                    {invoiceData?.supplierDetails?.supplierInfo?.msme ? (
                      <p className="text-gray-600 font-[400] text-[10px]">
                        MSME - {invoiceData?.supplierDetails?.supplierInfo?.cin}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="space-y-[2px] flex flex-col items-end">
                  <h2 className="text-[#B2B7C2]  text-display-md font-semibold">
                    Invoice
                  </h2>
                  <p className="text-gray-600 text-xs font-medium">
                    #{invoiceData?.invoiceNumber}
                  </p>
                </div>
              </div>

              <div className="border rounded-[15px] p-[18px] bg-[#FFFFFF] space-y-6">
                <div className="flex justify-between">
                  <div className="space-y-1 w-[40%]">
                    <p className="text-gray-600 text-[10px] font-medium">
                      Billed to
                    </p>
                    <div className="space-y-[2px]">
                      <p className="text-gray-900 font-semibold text-[10px]">
                        MIRATS INSIGHTS PRIVATE LIMITED
                      </p>
                      <p className="text-[#5E6470] text-[8px] font-[400]">
                        accounts@miratsinsights.com
                      </p>
                      <div>
                        <p className="text-[#5E6470] text-[8px] font-[400]">
                          C/O PITAMBER FURNITURES 1ST FLOOR, RING ROAD,
                          KALYANPUR, LUCKNOW, UTTAR PRADESH 226022 INDIA
                        </p>
                      </div>
                      <p className="text-[#5E6470] text-[8px] font-[400]">
                        GSTIN: 09AAPCM0779A1ZB
                      </p>
                      <p className="text-[#5E6470] text-[8px] font-[400]">
                        CIN: U73200UP2021PTC153917
                      </p>
                      <p className="text-[#5E6470] text-[8px] font-[400]">
                        PAN: AAPCM0779A
                      </p>
                      <p className="text-[#5E6470] text-[8px] font-[400]">
                        Place: Lucknow (09)
                      </p>
                      <p className="text-[#5E6470] text-[8px] font-[400]">
                        Contact Person & Phone: Avinash Kumar & +91 70040 69250
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col justify-between">
                    <div className="space-y-1">
                      <h2 className="text-gray-600 font-medium text-[10px]">
                        PO No.
                      </h2>
                      <p className="text-gray-900 font-semibold text-[10px]">
                        #{invoiceData?.poNumber}
                      </p>
                    </div>
                    <div className="space-y-1">
                      <h2 className="text-gray-600 font-medium text-[10px]">
                        Invoice No.
                      </h2>
                      <p className="text-gray-900 font-semibold text-[10px]">
                        {invoiceData?.invoiceNumber}
                      </p>
                    </div>
                    <div className="space-y-1">
                      <h2 className="text-gray-600 font-medium text-[10px]">
                        Invoice Date
                      </h2>
                      <p className="text-gray-900 font-semibold text-[10px]">
                        {invoiceData.invoiceDate
                          ? new Date(
                              invoiceData.invoiceDate
                            )?.toLocaleDateString("en-ca", {
                              month: "short",
                              year: "numeric",
                              day: "2-digit",
                            })
                          : "-"}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col justify-between">
                    <div className="space-y-1">
                      <h2 className="text-gray-600 font-medium text-[10px]">
                        Invoice of ({invoiceData.currency?.label})
                      </h2>
                      <p className="text-success-500 text-xl  font-bold">
                        {invoiceData.currency?.value}{" "}
                        {invoiceData.total?.toFixed(2) || 0}
                      </p>
                    </div>
                    <div className="space-y-1">
                      <h2 className="text-gray-600 font-medium text-[10px]">
                        Due date
                      </h2>
                      <p className="text-gray-900 font-semibold text-[10px]">
                        {invoiceData.dueDate
                          ? new Date(invoiceData.dueDate)?.toLocaleDateString(
                              "en-ca",
                              {
                                month: "short",
                                year: "numeric",
                                day: "2-digit",
                              }
                            )
                          : "-"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="p-[8px] border rounded-[4px] border-gray-200">
                  <p className="text-[8px] text-gray-900 ">
                    <span className="text-gray-600 font-medium">Note :</span>{" "}
                    {invoiceData.note}
                  </p>
                </div>
                <div className="space-y-6">
                  <div>
                    <div className="border-y py-2 flex justify-between items-center">
                      <div className="w-[127px] px-3">
                        <h2 className="text-[#5E6470] text-[8px] font-semibold uppercase">
                          Project Detail
                        </h2>
                      </div>
                      <div className="w-[66px] flex justify-center">
                        <h2 className="text-[#5E6470] text-[8px] font-semibold uppercase">
                          HSN/SAC
                        </h2>
                      </div>
                      <div className="w-[66px]  flex justify-center">
                        <h2 className="text-[#5E6470] text-[8px] font-semibold uppercase">
                          Qty
                        </h2>
                      </div>
                      <div className="w-[66px]  flex justify-center ">
                        <h2 className="text-[#5E6470] text-[8px] font-semibold uppercase">
                          Rate
                        </h2>
                      </div>
                      {hasGSTNo ? (
                        <div className="w-[66px]  flex justify-center">
                          <h2 className="text-[#5E6470] text-[8px] font-semibold uppercase">
                            Taxable Amount
                          </h2>
                        </div>
                      ) : (
                        <></>
                      )}

                      {isIndianBilling && hasGSTNo ? (
                        <div className="w-[66px]  flex justify-center">
                          <h2 className="text-[#5E6470] text-[8px] font-semibold uppercase">
                            Tax Amount
                          </h2>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div className="w-[66px]">
                        <p className="text-[#5E6470] text-[8px] text-end font-semibold uppercase">
                          Total Amount
                        </p>
                      </div>
                    </div>
                    {invoiceData?.projectList?.slice(0, 6)?.map((data, i) => {
                      return (
                        <div
                          className="flex justify-between items-center py-2"
                          key={i}
                        >
                          <div className="w-[127px] px-3 ">
                            <h2 className="text-gray-900 font-semibold text-[10px] ">
                              {data?.serviceName}
                            </h2>
                          </div>
                          <div className="w-[66px] text-gray-900  flex justify-center text-[10px] font-medium">
                            998371
                          </div>
                          <div className="w-[66px]  flex justify-center text-gray-900 text-[10px] font-medium">
                            {data?.quantity}
                          </div>
                          <div className="w-[66px] text-gray-900  flex justify-center text-[10px] font-medium">
                            {invoiceData?.currency?.value} {data?.rate}
                          </div>
                          {hasGSTNo ? (
                            <div className="w-[66px] text-gray-900  flex justify-center text-[10px] font-medium">
                              {invoiceData?.currency?.value}{" "}
                              {data?.taxableAmt?.toFixed(2)}
                            </div>
                          ) : (
                            <></>
                          )}

                          {isIndianBilling && hasGSTNo ? (
                            <div className="w-[66px] text-gray-900   flex justify-center text-[10px] font-medium">
                              {invoiceData?.currency?.value}{" "}
                              {data?.taxAmt?.toFixed(2)}
                            </div>
                          ) : (
                            <></>
                          )}

                          <div className="w-[66px] text-gray-900 text-[10px] font-medium flex justify-end">
                            {invoiceData?.currency?.value}{" "}
                            {data?.totalAmt?.toFixed(2)}
                          </div>
                        </div>
                      );
                    })}

                    {Array.isArray(invoiceData?.projectList) &&
                    invoiceData?.projectList?.length > 6 ? (
                      <></>
                    ) : (
                      <hr />
                    )}
                  </div>

                  {Array.isArray(invoiceData?.projectList) &&
                  invoiceData?.projectList?.length > 6 ? (
                    <></>
                  ) : (
                    <div className="flex flex-col items-end space-y-3 ">
                      {hasGSTNo ? (
                        <div className="flex w-[40%] justify-between items-center">
                          <h2 className="text-gray-900 text-[10px] font-medium">
                            Taxable Amount
                          </h2>
                          <p className="text-gray-900  text-[10px] font-medium">
                            {invoiceData?.currency?.value}{" "}
                            {invoiceData?.taxableAmount?.toFixed(2)}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}

                      {isIndianBilling && hasGSTNo ? (
                        <>
                          {stateObj[
                            invoiceData?.supplierDetails?.billingAddress?.state
                              ?.value
                              ? String(
                                  invoiceData?.supplierDetails?.billingAddress
                                    ?.state?.value
                                )
                              : ""
                          ] === "Uttar Pradesh" ? (
                            <>
                              <div className="flex w-[40%] justify-between items-center">
                                <h2 className="text-gray-900 text-[10px] font-medium">
                                  CGST (9%)
                                </h2>
                                <p className="text-gray-900  text-[10px] font-medium">
                                  {invoiceData?.currency?.value}{" "}
                                  {((invoiceData?.taxableAmount || 1) * 9) /
                                    100}
                                </p>
                              </div>
                              <div className="flex w-[40%] justify-between items-center">
                                <h2 className="text-gray-900 text-[10px] font-medium">
                                  SGST (9%)
                                </h2>
                                <p className="text-gray-900  text-[10px] font-medium">
                                  {invoiceData?.currency?.value}{" "}
                                  {((invoiceData?.taxableAmount || 1) * 9) /
                                    100}
                                </p>
                              </div>
                              <div className="flex w-[40%] justify-between items-center">
                                <h2 className="text-gray-900 text-[10px] font-medium">
                                  Subtotal
                                </h2>
                                <p className="text-gray-900  text-[10px] font-medium">
                                  {invoiceData?.currency?.value}{" "}
                                  {hasGSTNo ? (
                                    <>
                                      {(
                                        (invoiceData?.taxableAmount || 0) +
                                        ((invoiceData?.taxableAmount || 1) *
                                          9) /
                                          100 +
                                        ((invoiceData?.taxableAmount || 1) *
                                          9) /
                                          100
                                      )?.toFixed(2)}
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      {invoiceData?.projectList
                                        ?.reduce((a, b) => {
                                          return (a += b?.totalAmt);
                                        }, 0)
                                        ?.toFixed(2)}
                                    </>
                                  )}
                                </p>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="flex w-[40%] justify-between items-center">
                                <h2 className="text-gray-900 text-[10px] font-medium">
                                  IGST (18%)
                                </h2>
                                <p className="text-gray-900  text-[10px] font-medium">
                                  {invoiceData?.currency?.value}{" "}
                                  {(
                                    ((invoiceData?.taxableAmount || 1) * 18) /
                                    100
                                  )?.toFixed(2)}
                                </p>
                              </div>
                              <div className="flex w-[40%] justify-between items-center">
                                <h2 className="text-gray-900 text-[10px] font-medium">
                                  Subtotal
                                </h2>
                                <p className="text-gray-900  text-[10px] font-medium">
                                  {invoiceData?.currency?.value}{" "}
                                  {hasGSTNo ? (
                                    <>
                                      {(
                                        (invoiceData?.taxableAmount || 0) +
                                        ((invoiceData?.taxableAmount || 1) *
                                          18) /
                                          100
                                      )?.toFixed(2)}
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      {invoiceData?.projectList
                                        ?.reduce((a, b) => {
                                          return (a += b?.totalAmt);
                                        }, 0)
                                        ?.toFixed(2)}
                                    </>
                                  )}
                                </p>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="flex w-[40%] justify-between items-center">
                            <h2 className="text-gray-900 text-[10px] font-medium">
                              Subtotal
                            </h2>
                            <p className="text-gray-900  text-[10px] font-medium">
                              {invoiceData?.currency?.value}{" "}
                              {hasGSTNo ? (
                                <>
                                  {" "}
                                  {invoiceData?.taxableAmount?.toFixed(2) || 0}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  {invoiceData?.projectList
                                    ?.reduce((a, b) => {
                                      return (a += b?.totalAmt);
                                    }, 0)
                                    ?.toFixed(2)}
                                </>
                              )}
                            </p>
                          </div>
                        </>
                      )}

                      <div className="flex w-[40%] justify-between items-center">
                        <h2 className="text-gray-900 text-[10px] font-medium">
                          Adjustment
                        </h2>
                        <p className="text-gray-900  text-[10px] font-medium">
                          {invoiceData?.currency?.value}{" "}
                          {invoiceData?.adjustment
                            ? invoiceData?.adjustment
                            : 0}
                        </p>
                      </div>

                      <div className="w-[40%]">
                        <hr />
                      </div>
                      <div className="flex w-[40%] justify-between items-center">
                        <h2 className="text-gray-900 text-[10px] font-bold">
                          Total
                        </h2>
                        <p className="text-gray-900  text-[10px] font-bold">
                          {invoiceData?.currency?.value}{" "}
                          {invoiceData?.total
                            ? invoiceData?.total?.toFixed(2)
                            : 0}
                        </p>
                      </div>
                    </div>
                  )}
                </div>

                <div className="space-y-[10px]"></div>
              </div>

              <div className="space-y-2 px-[18px]">
                <h2 className="text-[#121722] font-semibold text-[10px]">
                  Financial Information
                </h2>
                <div className="flex justify-between items-center">
                  <div className="text-gray-600  text-[8px]">
                    <h2 className=" font-medium space-y-1">
                      {
                        invoiceData?.supplierDetails?.billingAddress
                          ?.companyName
                      }
                    </h2>
                    <p className="font-[400]">
                      {
                        invoiceData?.supplierDetails?.billingAddress
                          ?.addressLine1
                      }{" "}
                    </p>
                    <p className="font-[400]">
                      {
                        invoiceData?.supplierDetails?.billingAddress
                          ?.addressLine2
                      }
                    </p>
                    <p className="font-[400]">
                      {invoiceData?.supplierDetails?.billingAddress?.country
                        ?.label &&
                        `${invoiceData?.supplierDetails.billingAddress.country?.label}, `}
                      {invoiceData?.supplierDetails?.billingAddress?.state
                        ?.label &&
                        `${invoiceData?.supplierDetails.billingAddress.state.label}, `}
                      {invoiceData?.supplierDetails?.billingAddress?.city
                        ?.label &&
                        `${invoiceData?.supplierDetails.billingAddress.city.label}, `}
                      {invoiceData?.supplierDetails?.billingAddress?.zipcode &&
                        `${invoiceData?.supplierDetails.billingAddress.zipcode}`}
                    </p>
                    <p className="font-[400]">
                      Place of Supply -{" "}
                      {
                        invoiceData?.supplierDetails?.billingAddress?.state
                          ?.label
                      }
                    </p>
                  </div>
                  <div className="text-gray-600 text-[8px]">
                    <div className="flex  items-center gap-1">
                      <span>Account Holder Name -</span>
                      <span className="font-medium">
                        {
                          invoiceData?.supplierDetails?.bankingInfo
                            ?.accountHolder
                        }
                      </span>
                    </div>
                    <div className="flex  items-center gap-1">
                      <span>Account No. -</span>
                      <span className="font-medium">
                        {invoiceData?.supplierDetails?.bankingInfo?.accountNo}
                      </span>
                    </div>
                    <div className="flex  items-center gap-1">
                      <span>Bank Type -</span>
                      <span className="font-medium">
                        {
                          invoiceData?.supplierDetails?.bankingInfo?.bankType
                            ?.label
                        }
                      </span>
                    </div>
                    <div className="flex  items-center gap-1">
                      <span>IFSC Code -</span>
                      <span className="font-medium">
                        {invoiceData?.supplierDetails?.bankingInfo?.ifscCode}
                      </span>
                    </div>
                    <div className="flex items-center gap-1">
                      <span>Swift Code -</span>
                      <span className="font-medium">
                        {" "}
                        {invoiceData?.supplierDetails?.bankingInfo?.swiftCode}
                      </span>
                    </div>
                  </div>
                  <div className="text-gray-600 text-[8px]">
                    <div className="flex items-center  gap-1">
                      <span>Bank Name -</span>
                      <span className="font-medium">
                        {invoiceData?.supplierDetails?.bankingInfo?.bankName}
                      </span>
                    </div>
                    <div className="flex items-center gap-1">
                      <span>Branch Name -</span>
                      <span className="font-medium">
                        {invoiceData?.supplierDetails?.bankingInfo?.branchName}
                      </span>
                    </div>
                    <div className="flex items-center gap-1">
                      <span>GST No -</span>
                      <span className="font-medium">
                        {" "}
                        {invoiceData?.supplierDetails?.billingAddress?.gstNo}
                      </span>
                    </div>
                    <div className="flex items-center gap-1">
                      <span>LUT Code -</span>
                      <span className="font-medium">
                        {invoiceData?.supplierDetails?.billingAddress?.lutCode}
                      </span>
                    </div>
                    <div className="flex items-center gap-1">
                      <span>CIN No -</span>
                      <span className="font-medium">
                        {invoiceData?.supplierDetails?.supplierInfo?.cin}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {Array.isArray(invoiceData?.projectList) &&
          invoiceData?.projectList?.length > 6 ? (
            remainingBatches.map((batch, batchIndex) => {
              const isLastPage = batchIndex + 1 === remainingBatches.length - 1;

              return (
                <div
                  key={batchIndex}
                  className={` bg-[#f5faff]   px-[18px] py-6 space-y-[18px] min-h-[99vh] pdf-page `}
                >
                  <div className="border rounded-[15px] p-[18px] bg-[#FFFFFF] space-y-6">
                    <div className="space-y-6">
                      <div>
                        <div className="border-y py-2 flex justify-between items-center">
                          <div className="w-[127px] px-3">
                            <h2 className="text-[#5E6470] text-[8px] font-semibold uppercase">
                              Project Detail
                            </h2>
                          </div>
                          <div className="w-[66px] flex justify-center">
                            <h2 className="text-[#5E6470] text-[8px] font-semibold uppercase">
                              HSN/SAC
                            </h2>
                          </div>
                          <div className="w-[66px]  flex justify-center">
                            <h2 className="text-[#5E6470] text-[8px] font-semibold uppercase">
                              Qty
                            </h2>
                          </div>
                          <div className="w-[66px]  flex justify-center ">
                            <h2 className="text-[#5E6470] text-[8px] font-semibold uppercase">
                              Rate
                            </h2>
                          </div>
                          {hasGSTNo ? (
                            <div className="w-[66px]  flex justify-center">
                              <h2 className="text-[#5E6470] text-[8px] font-semibold uppercase">
                                Taxable Amount
                              </h2>
                            </div>
                          ) : (
                            <></>
                          )}

                          {isIndianBilling && hasGSTNo ? (
                            <div className="w-[66px]  flex justify-center">
                              <h2 className="text-[#5E6470] text-[8px] font-semibold uppercase">
                                Tax Amount
                              </h2>
                            </div>
                          ) : (
                            <></>
                          )}

                          <div className="w-[66px]">
                            <p className="text-[#5E6470] text-[8px] text-end font-semibold uppercase">
                              Total Amount
                            </p>
                          </div>
                        </div>
                        {batch?.map((data, i) => {
                          return (
                            <div
                              className="flex justify-between items-center py-2"
                              key={i}
                            >
                              <div className="w-[127px] px-3 ">
                                <h2 className="text-gray-900 font-semibold text-[10px] ">
                                  {data?.serviceName}
                                </h2>
                              </div>
                              <div className="w-[66px] text-gray-900  flex justify-center text-[10px] font-medium">
                                998371
                              </div>
                              <div className="w-[66px]  flex justify-center text-gray-900 text-[10px] font-medium">
                                {data?.quantity}
                              </div>
                              <div className="w-[66px] text-gray-900  flex justify-center text-[10px] font-medium">
                                {invoiceData?.currency?.value} {data?.rate}
                              </div>
                              {hasGSTNo ? (
                                <div className="w-[66px] text-gray-900  flex justify-center text-[10px] font-medium">
                                  {invoiceData?.currency?.value}{" "}
                                  {data?.taxableAmt?.toFixed(2)}
                                </div>
                              ) : (
                                <></>
                              )}

                              {isIndianBilling && hasGSTNo ? (
                                <div className="w-[66px] text-gray-900   flex justify-center text-[10px] font-medium">
                                  {invoiceData?.currency?.value}{" "}
                                  {data?.taxAmt?.toFixed(2)}
                                </div>
                              ) : (
                                <></>
                              )}

                              <div className="w-[66px] text-gray-900 text-[10px] font-medium flex justify-end">
                                {invoiceData?.currency?.value}{" "}
                                {data?.totalAmt?.toFixed(2)}
                              </div>
                            </div>
                          );
                        })}

                        {isLastPage ? <></> : <hr />}
                      </div>

                      {isLastPage ? (
                        <></>
                      ) : (
                        <div className="flex flex-col items-end space-y-3 ">
                          {hasGSTNo ? (
                            <div className="flex w-[40%] justify-between items-center">
                              <h2 className="text-gray-900 text-[10px] font-medium">
                                Taxable Amount
                              </h2>
                              <p className="text-gray-900  text-[10px] font-medium">
                                {invoiceData?.currency?.value}{" "}
                                {invoiceData?.taxableAmount?.toFixed(2)}
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}

                          {isIndianBilling && hasGSTNo ? (
                            <>
                              {stateObj[
                                invoiceData?.supplierDetails?.billingAddress
                                  ?.state?.value
                                  ? String(
                                      invoiceData?.supplierDetails
                                        ?.billingAddress?.state?.value
                                    )
                                  : ""
                              ] === "Uttar Pradesh" ? (
                                <>
                                  <div className="flex w-[40%] justify-between items-center">
                                    <h2 className="text-gray-900 text-[10px] font-medium">
                                      CGST (9%)
                                    </h2>
                                    <p className="text-gray-900  text-[10px] font-medium">
                                      {invoiceData?.currency?.value}{" "}
                                      {((invoiceData?.taxableAmount || 1) * 9) /
                                        100}
                                    </p>
                                  </div>
                                  <div className="flex w-[40%] justify-between items-center">
                                    <h2 className="text-gray-900 text-[10px] font-medium">
                                      SGST (9%)
                                    </h2>
                                    <p className="text-gray-900  text-[10px] font-medium">
                                      {invoiceData?.currency?.value}{" "}
                                      {((invoiceData?.taxableAmount || 1) * 9) /
                                        100}
                                    </p>
                                  </div>
                                  <div className="flex w-[40%] justify-between items-center">
                                    <h2 className="text-gray-900 text-[10px] font-medium">
                                      Subtotal
                                    </h2>
                                    <p className="text-gray-900  text-[10px] font-medium">
                                      {invoiceData?.currency?.value}{" "}
                                      {hasGSTNo ? (
                                        <>
                                          {(
                                            (invoiceData?.taxableAmount || 0) +
                                            ((invoiceData?.taxableAmount || 1) *
                                              9) /
                                              100 +
                                            ((invoiceData?.taxableAmount || 1) *
                                              9) /
                                              100
                                          )?.toFixed(2)}
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          {invoiceData?.projectList
                                            ?.reduce((a, b) => {
                                              return (a += b?.totalAmt);
                                            }, 0)
                                            ?.toFixed(2)}
                                        </>
                                      )}
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="flex w-[40%] justify-between items-center">
                                    <h2 className="text-gray-900 text-[10px] font-medium">
                                      IGST (18%)
                                    </h2>
                                    <p className="text-gray-900  text-[10px] font-medium">
                                      {invoiceData?.currency?.value}{" "}
                                      {(
                                        ((invoiceData?.taxableAmount || 1) *
                                          18) /
                                        100
                                      )?.toFixed(2)}
                                    </p>
                                  </div>
                                  <div className="flex w-[40%] justify-between items-center">
                                    <h2 className="text-gray-900 text-[10px] font-medium">
                                      Subtotal
                                    </h2>
                                    <p className="text-gray-900  text-[10px] font-medium">
                                      {invoiceData?.currency?.value}{" "}
                                      {hasGSTNo ? (
                                        <>
                                          {(
                                            (invoiceData?.taxableAmount || 0) +
                                            ((invoiceData?.taxableAmount || 1) *
                                              18) /
                                              100
                                          )?.toFixed(2)}
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          {invoiceData?.projectList
                                            ?.reduce((a, b) => {
                                              return (a += b?.totalAmt);
                                            }, 0)
                                            ?.toFixed(2)}
                                        </>
                                      )}
                                    </p>
                                  </div>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {/* <div className="flex w-[40%] justify-between items-center">
                            <h2 className="text-gray-900 text-[10px] font-medium">
                              Tax (0%)
                            </h2>
                            <p className="text-gray-900  text-[10px] font-medium">
                              {invoiceData?.currency?.value} {0}
                            </p>
                          </div> */}
                              <div className="flex w-[40%] justify-between items-center">
                                <h2 className="text-gray-900 text-[10px] font-medium">
                                  Subtotal
                                </h2>
                                <p className="text-gray-900  text-[10px] font-medium">
                                  {invoiceData?.currency?.value}{" "}
                                  {hasGSTNo ? (
                                    <>
                                      {" "}
                                      {invoiceData?.taxableAmount?.toFixed(2) ||
                                        0}
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      {invoiceData?.projectList
                                        ?.reduce((a, b) => {
                                          return (a += b?.totalAmt);
                                        }, 0)
                                        ?.toFixed(2)}
                                    </>
                                  )}
                                </p>
                              </div>
                            </>
                          )}

                          <div className="flex w-[40%] justify-between items-center">
                            <h2 className="text-gray-900 text-[10px] font-medium">
                              Adjustment
                            </h2>
                            <p className="text-gray-900  text-[10px] font-medium">
                              {invoiceData?.currency?.value}{" "}
                              {invoiceData?.adjustment
                                ? invoiceData?.adjustment
                                : 0}
                            </p>
                          </div>

                          <div className="w-[40%]">
                            <hr />
                          </div>
                          <div className="flex w-[40%] justify-between items-center">
                            <h2 className="text-gray-900 text-[10px] font-bold">
                              Total
                            </h2>
                            <p className="text-gray-900  text-[10px] font-bold">
                              {invoiceData?.currency?.value}{" "}
                              {invoiceData?.total
                                ? invoiceData?.total?.toFixed(2)
                                : 0}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="space-y-[10px]"></div>
                  </div>

                  <div className="space-y-2 px-[18px]">
                    <h2 className="text-[#121722] font-semibold text-[10px]">
                      Financial Information
                    </h2>
                    <div className="flex justify-between items-center">
                      <div className="text-gray-600  text-[8px]">
                        <h2 className=" font-medium space-y-1">
                          {
                            invoiceData?.supplierDetails?.billingAddress
                              ?.companyName
                          }
                        </h2>
                        <p className="font-[400]">
                          {
                            invoiceData?.supplierDetails?.billingAddress
                              ?.addressLine1
                          }{" "}
                        </p>
                        <p className="font-[400]">
                          {
                            invoiceData?.supplierDetails?.billingAddress
                              ?.addressLine2
                          }
                        </p>
                        <p className="font-[400]">
                          {invoiceData?.supplierDetails?.billingAddress?.country
                            ?.label &&
                            `${invoiceData?.supplierDetails.billingAddress.country?.label}, `}
                          {invoiceData?.supplierDetails?.billingAddress?.state
                            ?.label &&
                            `${invoiceData?.supplierDetails.billingAddress.state.label}, `}
                          {invoiceData?.supplierDetails?.billingAddress?.city
                            ?.label &&
                            `${invoiceData?.supplierDetails.billingAddress.city.label}, `}
                          {invoiceData?.supplierDetails?.billingAddress
                            ?.zipcode &&
                            `${invoiceData?.supplierDetails.billingAddress.zipcode}`}
                        </p>
                        <p className="font-[400]">
                          Place of Supply -{" "}
                          {
                            invoiceData?.supplierDetails?.billingAddress?.state
                              ?.label
                          }
                        </p>
                      </div>
                      <div className="text-gray-600 text-[8px]">
                        <div className="flex  items-center gap-1">
                          <span>Account Holder Name -</span>
                          <span className="font-medium">
                            {
                              invoiceData?.supplierDetails?.bankingInfo
                                ?.accountHolder
                            }
                          </span>
                        </div>
                        <div className="flex  items-center gap-1">
                          <span>Account No. -</span>
                          <span className="font-medium">
                            {
                              invoiceData?.supplierDetails?.bankingInfo
                                ?.accountNo
                            }
                          </span>
                        </div>
                        <div className="flex  items-center gap-1">
                          <span>Bank Type -</span>
                          <span className="font-medium">
                            {
                              invoiceData?.supplierDetails?.bankingInfo
                                ?.bankType?.label
                            }
                          </span>
                        </div>
                        <div className="flex  items-center gap-1">
                          <span>IFSC Code -</span>
                          <span className="font-medium">
                            {
                              invoiceData?.supplierDetails?.bankingInfo
                                ?.ifscCode
                            }
                          </span>
                        </div>
                        <div className="flex items-center gap-1">
                          <span>Swift Code -</span>
                          <span className="font-medium">
                            {" "}
                            {
                              invoiceData?.supplierDetails?.bankingInfo
                                ?.swiftCode
                            }
                          </span>
                        </div>
                      </div>
                      <div className="text-gray-600 text-[8px]">
                        <div className="flex items-center  gap-1">
                          <span>Bank Name -</span>
                          <span className="font-medium">
                            {
                              invoiceData?.supplierDetails?.bankingInfo
                                ?.bankName
                            }
                          </span>
                        </div>
                        <div className="flex items-center gap-1">
                          <span>Branch Name -</span>
                          <span className="font-medium">
                            {" "}
                            {
                              invoiceData?.supplierDetails?.bankingInfo
                                ?.branchName
                            }
                          </span>
                        </div>
                        <div className="flex items-center gap-1">
                          <span>GST No -</span>
                          <span className="font-medium">
                            {" "}
                            {
                              invoiceData?.supplierDetails?.billingAddress
                                ?.gstNo
                            }
                          </span>
                        </div>
                        <div className="flex items-center gap-1">
                          <span>LUT Code -</span>
                          <span className="font-medium">
                            {
                              invoiceData?.supplierDetails?.billingAddress
                                ?.lutCode
                            }
                          </span>
                        </div>
                        <div className="flex items-center gap-1">
                          <span>CIN No -</span>
                          <span className="font-medium">
                            {invoiceData?.supplierDetails?.supplierInfo?.cin}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailInvoice;
